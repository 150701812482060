import { useEffect } from "react";

export default function TransactionDetails({request, bill}) {

  useEffect(()=>{},[bill])

  return (
    <>
      <div>
        <p className="text-base mt-4 font-semibold">Transaction:</p>
      </div>
      <div>
        <div className="mx-2 sm:mx-6 border-b-1 border-gray-300 pb-1 grid grid-cols-2">
          <div className="text-left leading-7 font-semibold text-sm">
            <p>Subtotal x {request?.quantity}</p>

            <p>
              Shipping Cost <span className="text-xs">(Inc GST)</span>
            </p>
            <p>Warranty</p>
            <p>Tax (Inc GST)</p>
          </div>
          <div className="text-right leading-7 text-sm">
            <p>
              {bill.partPrice > 0
                ? "$" + (bill.partPrice * request?.quantity).toFixed(2)
                : "$00"}
            </p>

            <p>{bill.freightPrice > 0 ? "$" + bill.freightPrice : "$00"}</p>
            <p>{bill.warrantyPrice ? "$" + bill.warrantyPrice : "$00"}</p>
            <p>${bill.totalTax ? bill.totalTax : "00"}</p>
          </div>
        </div>
        <div className="flex justify-between mx-2 sm:mx-6 py-2 text-base font-semibold">
          <div>
            <p>Grand Total</p>
          </div>
          <div>
            <p>{bill.totalPrice > 0 ? "$" + bill.totalPrice : "$00"}</p>
          </div>
        </div>
      </div>
    </>
  );
}
