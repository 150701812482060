import {
  Button,
  Input,
  Select,
  SelectItem,
  Checkbox,
  useDisclosure,
  Modal,
  ModalContent,
  ModalBody,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import SettingsLayout from "../layouts/SettingsLayout";
import { inputStyle, inputWrapperStyle } from "../data/inputStyleData";
import { getCities, getStates } from "../store/slices/countrySlice";
import { addAuth, updateProfileImg } from "../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Resizer from "react-image-file-resizer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import axios from "axios";

const UserProfile = () => {
  const user_role = localStorage.getItem("role");
  const accessToken = localStorage.getItem("accessToken");
  const supplierId = localStorage.getItem("user_id");
  const { status, user } = useSelector((state) => state.auth);
  const { states, cities } = useSelector((state) => state.country);
  const [state, setState] = useState();
  const [city, setCity] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isChecked, setIsChecked] = useState(false);
  const [Industry, setIndustry] = useState("");

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const {
    isOpen: addAssignee,
    onOpen: sumAssignee,
    onClose: closeAssignee,
  } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      country: "Australia",
    },
  });

  const updateUser = async () => {
    let res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/me`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(
      addAuth({
        ...res.data.details,
      })
    );
  };

  const handleUpload = async (e) => {
    let img = e.target.files;
    try {
      Resizer.imageFileResizer(
        img[0],
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          dispatch(updateProfileImg(uri));
          if (status === "idle") {
            updateUser();
            toast.success("Updated successfully ⭐");
            window.location.reload();
          } else if (status === "error") {
            toast.error("Oops! Something went wrong.");
          }
        },
        "file",
        200,
        200
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleStripe = async () => {
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/payment/registerSupplierStripe`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      window.open(res.data.url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const addModel = () => {
    sumAssignee();
  };

  const onSubmit = async (data) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/users/update`,
        {
          ...data,
          suburb: city,
          state: state,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response) {
        updateUser();
      }
      toast.success("Profile Updated successfully ⭐");
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  };

  const handleCancel = () => {
    navigate("/requests");
  };

  const handleStateChange = async (e) => {
    const data = {
      state: e?.currentKey,
    };
    setState(e?.currentKey);
    dispatch(getCities(data));
  };

  useEffect(() => {
    dispatch(getStates());
    if (user?.state) {
      dispatch(getCities({ state: user?.state }));
      setState(user?.state);
    }
  }, [dispatch, user?.state]);

  useEffect(() => {}, [states, cities, state, city]);

  useEffect(() => {
    if (user) {
      if (user_role === "CUSTOMER") {
        reset({
          first_name: user.first_name,
          last_name: user.last_name,
          phone: user.phone,
          address_line_1: user.address_line_1,
          address_line_2: user.address_line_2,
          suburb: user.suburb,
          postcode: user.postcode,
          business_name: user.business_name,
          capricorn_number: user.capricorn_number,
          Industry: user.Industry,
        });
      } else {
        reset({
          first_name: user.first_name,
          last_name: user.last_name,
          phone: user.phone,
          address_line_1: user.address_line_1,
          address_line_2: user.address_line_2,
          suburb: user.suburb,
          postcode: user.postcode,
          company_name: user.company_name,
          capricorn_number: user.capricorn_number,
        });
      }
      setCity(user?.suburb);
    }
  }, [user, user?.profile_id, user_role, reset]);

  return (
    <div className="px-2 sm:px-8 md:px-14">
      <div className="py-4 flex flex-col md:flex-row gap-4 md:gap-0 md:justify-between md:items-center">
        <div className="py-3 flex flex-col sm:flex-row items-center gap-4">
          {user?.profile_id ? (
            <img
              src={user.profile_id}
              alt="profile-pic"
              height="120px"
              width="120px"
              className="rounded-full"
            />
          ) : (
            <div className="w-28 h-28 bg-gray-200 border-1 border-gray-500 rounded-full"></div>
          )}
          <div className="text-center sm:text-left">
            <p className="text-base font-semibold">
              <span>{user?.email}</span>
            </p>
            <p className="text-xs">{user?.role}</p>
            {user?.blocked === true && (
              <Button className="bg-red-400 text-white font-semibold px-7 mt-3 mobile-only:w-full">
                Blocked
              </Button>
            )}
          </div>
        </div>
        <div>
          <Input
            type="file"
            label="Edit your profile image"
            className="mb-2"
            classNames={{
              input: [...inputStyle, "user-profile"],
              inputWrapper: inputWrapperStyle,
            }}
            accept="image/*"
            labelPlacement={"outside"}
            placeholder="Choose images"
            description={"( File should not be greater than 5MB )"}
            onChange={(e) => handleUpload(e)}
          />
          {user_role === "SUPPLIER" && (
            <Button variant="bordered" onClick={handleStripe}>
              Connect Stripe
            </Button>
          )}
        </div>
      </div>
      <div className="text-xl font-semibold border-b-1 border-gray-300 pb-2 mb-6">
        Edit Your Profile
      </div>
      <form className="md:px-10 space-y-12" onSubmit={handleSubmit(onSubmit)}>
        <SettingsLayout heading="Personal info">
          <div className="grid sm:grid-cols-2 gap-6">
            <Input
              type="text"
              label="First Name"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("first_name", {
                required: "This field is required",
              })}
            />
            <Input
              type="text"
              label="Last Name"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("last_name", {
                required: "This field is required",
              })}
            />
            <Input
              type="number"
              label="Phone Number"
              labelPlacement="outside"
              variant="bordered"
              placeholder=" "
              {...register("phone", {
                required: "This field is required",
              })}
            />
            {user_role === "ADMIN" ? (
              <Input
                type="password"
                label="Password"
                labelPlacement="outside"
                variant="bordered"
                placeholder=" "
                {...register("password", {
                  required: "This field is required",
                })}
              />
            ) : (
              <div className="flex justify-end">
                <Button
                  onClick={() => addModel()}
                  className="bg-white text-blue-500 border border-lightBlue mt-6 font-semibold mb-5 w-1/2 px-7 mobile-only:w-full md:px-16"
                >
                  Contract Info
                </Button>
              </div>
            )}
          </div>
        </SettingsLayout>

        <SettingsLayout heading="Business Info">
          {user_role === "SUPPLIER" ? (
            <div className="grid sm:grid-cols-2 gap-6">
              <Input
                isReadOnly
                type="text"
                label="Company Name"
                labelPlacement="outside"
                classNames={{
                  input: ["capitalize", "cursor-not-allowed"],
                }}
                variant="bordered"
                placeholder=" "
                value={user?.company_name}
              />
              <Input
                isReadOnly
                type="number"
                label="ABN Number"
                labelPlacement="outside"
                value={user?.abn_number}
                classNames={{
                  input: ["capitalize", "cursor-not-allowed"],
                }}
                variant="bordered"
                placeholder="0"
              />
              <Input
                isReadOnly
                type="text"
                label="Supplier Type"
                labelPlacement="outside"
                value={user?.supplierType?.name}
                classNames={{
                  input: ["capitalize", "cursor-not-allowed"],
                }}
                variant="bordered"
                placeholder=" "
              />

              <Input
                isReadOnly
                type="text"
                label="Commission Rate"
                labelPlacement="outside"
                value={user?.CommissionRate?.rate}
                classNames={{
                  input: ["capitalize", "cursor-not-allowed"],
                }}
                variant="bordered"
                placeholder=" "
              />

              <Input
                type="number"
                label="Capricorn Number"
                labelPlacement="outside"
                classNames={{
                  input: ["capitalize"],
                }}
                onWheel={(e) => e.target.blur()}
                variant="bordered"
                placeholder=" "
                {...register("capricorn_number", {
                  required: false,
                })}
              />
            </div>
          ) : (
            <div className="grid sm:grid-cols-2 gap-6">
              <Input
                type="text"
                label="Business Name"
                labelPlacement="outside"
                classNames={{
                  input: ["capitalize"],
                }}
                variant="bordered"
                placeholder=" "
                {...register("business_name", {
                  required: false,
                })}
              />

              <Input
                type="number"
                label="Capricorn Number"
                labelPlacement="outside"
                classNames={{
                  input: ["capitalize"],
                }}
                variant="bordered"
                placeholder=" "
                {...register("capricorn_number", {
                  required: false,
                })}
              />
              <Select
                {...register("Industry")}
                variant="bordered"
                label="Industry"
                labelPlacement="outside"
                placeholder=" "
                selectedKeys={[watch("Industry")]}
              >
                <SelectItem key="Collision Repairer" value="Collision Repairer">
                  Collision Repairer
                </SelectItem>
                <SelectItem key="Dismantler" value="Dismantler">
                  Dismantler
                </SelectItem>
                <SelectItem key="Mechanic" value="Mechanic">
                  Mechanic
                </SelectItem>
                <SelectItem key="Private" value="Private">
                  Private
                </SelectItem>
                <SelectItem key="Reseller" value="Reseller">
                  Reseller
                </SelectItem>
              </Select>
            </div>
          )}
        </SettingsLayout>

        <SettingsLayout heading="Address info">
          <div className="grid sm:grid-cols-2 gap-6">
            <Input
              type="text"
              label="Address Line 1"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("address_line_1", {
                required: false,
              })}
            />
            <Input
              type="text"
              label="Address Line 2"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("address_line_2", {
                required: false,
              })}
            />
            <Input
              {...register("country")}
              type="text"
              variant="bordered"
              label="Country"
              labelPlacement="outside"
              placeholder=" "
              isReadOnly
              className="pointer-events-none"
            />

            <Select
              selectedKeys={[state]}
              variant="bordered"
              label="State"
              labelPlacement="outside"
              placeholder=" "
              onSelectionChange={handleStateChange}
            >
              {states?.length > 0 ? (
                states.map((item) => (
                  <SelectItem key={item.name}>{item.name}</SelectItem>
                ))
              ) : (
                <SelectItem key={"No data"}>Fetching the data...</SelectItem>
              )}
            </Select>
            <Input
              type="text"
              label="City"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("suburb", {
                required: false,
              })}
            />
            <Input
              type="text"
              label="Post Code"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("postcode", {
                required: false,
              })}
            />
          </div>
        </SettingsLayout>

        <SettingsLayout divider={false}>
          <div className="">
            <Button
              className="bg-lightBlue text-white font-semibold mb-5 px-7 mobile-only:w-full"
              type="submit"
            >
              Save Changes
            </Button>
            <Button
              className="bg-purpleBtn text-white font-semibold px-7 sm:ml-7 mobile-only:w-full"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </SettingsLayout>
      </form>
      <Modal
        className="w-full "
        size={"md"}
        isOpen={addAssignee}
        onClose={closeAssignee}
      >
        <ModalContent>
          {(onClose) => (
            <ModalBody>
              <h1 className="text-center font-semibold text-2xl  text-gray-800 ">
                Contract Info
              </h1>
              <div className="h-[400px] px-2 overflow-y-scroll border border-gray-400 rounded-lg">
                <p className="text-xs mt-2">
                  By agreeing to the terms and conditions mentioned in this
                  document, you are bound to follow all the instructions as
                  mentioned below.
                </p>
                {user_role === "SUPPLIER" ? (
                  <div className="my-2 py-1">
                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      1. Supplier Agreement
                    </h2>

                    <p className="text-xs">
                      This agreement includes all the terms and conditions that
                      a supplier must follow to use the website smoothly. This
                      document consists of guidelines regarding the services and
                      rules. Suppliers should not engage in any misleading or
                      illegal activities.
                    </p>
                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      2. General Guidelines for Suppliers
                    </h2>
                    <p className="text-xs">
                      Suppliers must provide full, correct, true, and accurate
                      information regarding the products. The supplier is solely
                      responsible for the quality and quantity of the products
                      sold by them. Suppliers must not post any product or
                      information that is misleading to the customer or engage
                      in any illegal activity.
                    </p>

                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      3. Terms of Service on Wreckonnect
                    </h2>
                    <p className="text-xs">
                      By accepting the Terms of Service, you undertake that you
                      are accessing the website and transacting at your own
                      risk. We just provide the service and are not responsible
                      for any type of losses. If you are promising a warranty or
                      serving any service, then you must fulfill it. All
                      disputes must be resolved by you.
                    </p>

                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      4. What happens if you violates supplier rules
                    </h2>
                    <p className="text-xs">
                      If the supplier violates any rules or code of conduct, we
                      have the right to block the supplier's account and suspend
                      the process.
                    </p>
                  </div>
                ) : (
                  <div className="my-2 py-1">
                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      1. Collection of your info
                    </h2>

                    <p className="text-xs">
                      If you are using this website, you are responsible for
                      maintaining the confidentiality of your account and
                      password, as well as all activities that occur under your
                      account name. You must take all necessary steps to ensure
                      that your password is kept confidential and not shared
                      with anyone. If there is any misuse of your account,
                      please change your password immediately. Your information
                      is safe with us. Kindly update your email on time, as it
                      is important information that gives you a unique identity.
                      Please ensure that the details you provide to us are
                      accurate and updated, and do not provide any misleading
                      information.
                    </p>
                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      2. Disclaimer of Warranty
                    </h2>
                    <p className="text-xs">
                      Each product sold by the supplier comes with a warranty
                      duration, which may vary from supplier to supplier
                      according to the product. There is also an optional
                      warranty plan. Kindly check the warranty duration before
                      accepting the quote.
                    </p>

                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      3. Payment
                    </h2>
                    <p className="text-xs">
                      While processing payments, we are not responsible for any
                      type of refund. If any order is disputed or canceled for
                      any reason, the supplier will be liable to refund the
                      amount.
                    </p>

                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      4. Resolution of Disputes
                    </h2>
                    <p className="text-xs">
                      If disputes arise due to any defect in the product or
                      service, they will be handled carefully and a solution
                      will be provided. The disputes will be resolved promptly.
                    </p>

                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      5. Platform for Transaction and Communication
                    </h2>
                    <p className="text-xs">
                      This website provides a platform only for transactions and
                      communication. It helps you to find the required parts
                      from the required seller. It establishes a connection
                      between the Supplier and Customer and does not involve any
                      physical interaction.
                    </p>
                  </div>
                )}
              </div>
              <div className="mt-1 mb-1">
                <Checkbox
                  required
                  size="sm"
                  color="blue"
                  onChange={handleCheckboxChange}
                >
                  Accept Terms and Conditions
                </Checkbox>
              </div>
              <div className="grid grid-cols-4 gap-2 pb-3">
                <div></div>
                <div></div>

                <Button
                  className="border-2 h-8 border-orange-500 text-orange-500 bg-transparent"
                  onClick={() => closeAssignee()}
                >
                  Cancel
                </Button>
                <Button
                  isDisabled={!isChecked}
                  onClick={() => closeAssignee()}
                  className="border-2 h-8 border-green-500 text-green-500 bg-transparent  "
                >
                  Next
                </Button>
              </div>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default UserProfile;
