import React from "react";
import { Button } from "@nextui-org/react";

const CustomButton = ({ text }) => {
  return (
    <Button
      fullWidth
      type="submit"
      className="bg-lightBlue hover:bg-blue-400 text-white font-semibold"
    >
      {text}
    </Button>
  );
};

export default CustomButton;
