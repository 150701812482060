import React, { useEffect, useState } from "react";
import { Button, useDisclosure, Modal, ModalBody, ModalContent, ModalFooter, Select, SelectItem, Spinner } from "@nextui-org/react";
import { MdHome, MdOutlineHourglassBottom, MdOutlineLocalShipping } from "react-icons/md";
import OrderGenerate from "../pages/Customer/OrderGenerate";
import { LiaShippingFastSolid } from "react-icons/lia";
import { IoCartOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { CiSettings } from "react-icons/ci";
import { toast } from "react-toastify";
import axios from "axios";
import ChatBox from "./ChatBox";

const active_style = "flex items-center w-full text-lightBlue-300 after:content-[''] after:text-xs after:w-full after:h-1 after:border-b after:border-lightBlue after:border-4 after:inline-block relative";

const inActive_style = "flex items-center w-full after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-4 after:inline-block relative";

const nestedActiveStyle = "flex items-center justify-center w-7 h-7 rounded-full lg:h-10 lg:w-10 shrink-0 after:font-semibold after:absolute after:top-11 after:text-[0.5rem] text-white sm:after:text-xs";

const order_status = [
  {
    id: 1,
    status: "Initiated",
    value: "Initiated",
    completed: false,
    icon: <IoCartOutline />
  },
  {
    id: 2,
    status: "Processing",
    value: "Processing",
    completed: false,
    icon: <CiSettings />
  },
  {
    id: 3,
    status: "Shipped",
    value: "Shipped",
    completed: false,
    icon: <MdOutlineLocalShipping />
  },
  {
    id: 4,
    status: "Transit",
    value: "In Transit",
    completed: false,
    icon: <LiaShippingFastSolid />
  },
  {
    id: 5,
    status: "Delivered",
    value: "Delivered",
    completed: false,
    icon: <MdOutlineHourglassBottom />
  },
  {
    id: 6,
    status: "Completed",
    value: "Completed",
    completed: false,
    icon: <MdHome />
  },
];

const OrderDetails = () => {
  const { id } = useParams();
  const role = localStorage.getItem("role");
  const [orderData, setOrderData] = useState([]);
  const [updateStatus, setUpdateStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = {};
    if (role === 'CUSTOMER') {
      data = {
        status: "Completed"
      };
    } else if (role === "SUPPLIER") {
      data = {
        status: updateStatus
      };
    }
    try {
      let token = localStorage.getItem("accessToken");
      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/orders/${id}/status`
        , data, header);
      toast.success(res.data?.message);
      window.location.reload();
      onClose();
    } catch (error) {
      const err = error?.response?.data?.message;
      toast.error(err);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("accessToken");
        const header = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/orders/${id}`,
          header
        );
        let data = res.data.details;
        setOrderData(res.data.details?.order);
        setOrderStatus(data.status);
      } catch (error) {
        let err = error.response?.data?.message;
        toast.error(err);
      }
    })()

  }, [order_status, updateStatus, id, role]);

  useEffect(() => { }, [orderData, orderStatus])

  return (
    <div>
      {orderData && orderStatus ?
        <div>
          <div className="flex flex-col justify-center items-center border-1 border-regalBlue">
            <div className="text-sm mobile-only:text-xs font-semibold bg-regalBlue text-white w-full py-4 text-center">
              <span className="uppercase">Tracking Number: </span> <span>{orderData?.tracking_number}</span>
            </div>
            <div className="py-5 w-full sm:w-[70%] m-auto">
              <ol className="flex items-center w-full my-4 ml-2 sm:ml-10">
                {orderStatus.map((item, index) =>
                  <li className={`${item.completed ? active_style : inActive_style} ${item.id === 6 ? "after:!hidden" : ""}`} key={item.id}>
                    <div
                      after-dynamic-value={order_status[index].status}
                      className={`${nestedActiveStyle} ${item.completed ? `bg-lightBlue after:text-lightBlue` : `bg-gray-200 !text-gray-700 after:text-gray-700`} after:content-[attr(after-dynamic-value)]`}
                    >
                      {order_status[index].icon}
                    </div>
                  </li>
                )}
              </ol>
              {orderData.OrderStatus?.status && orderData.OrderStatus?.status !== "Dispute" && orderData.OrderStatus?.status !== "Cancelled" && <div className="flex justify-center mt-10">
                {role === "CUSTOMER" ?
                  orderData.OrderStatus?.status === "Delivered" ?
                    (<Button className="border-lightBlue border-2 bg-transparent hover:bg-lightBlue text-lightBlue hover:text-white font-semibold" onClick={handleSubmit}>
                      Received Product
                    </Button>)
                    : " "
                  :
                  orderData.OrderStatus?.status === "Delivered" || orderData.OrderStatus?.status === "Completed" ? ""
                    : (<Button
                      className="border-lightBlue border-2 bg-transparent hover:bg-lightBlue text-lightBlue hover:text-white font-semibold"
                      onClick={() => onOpen()}
                    >
                      Update the status
                    </Button>)}
              </div>}
            </div>
          </div>
          <OrderGenerate orderData={orderData} />
        </div>
        :
        <div className="flex justify-center items-center mt-3">
          <Spinner />
        </div>
      }

      {/* ----- modal for dispute ---- */}
      <Modal size={"sm"} placement="center" isOpen={isOpen} onClose={onClose} className="p-3">
        <ModalContent>
          {(onClose) => (
            <>
              <form onSubmit={handleSubmit}>
                <ModalBody className="w-full">
                  <p className="text-base font-semibold text-center">
                    Update the status
                  </p>
                  <Select
                    labelPlacement={"outside"}
                    variant={"bordered"}
                    label="Order Status"
                    placeholder="Select status"
                    className="w-full"
                    onSelectionChange={(e) => setUpdateStatus(e.anchorKey)}
                  >
                    {orderStatus.map((item, index) =>
                      <SelectItem key={item.status === "Transit" ? "In Transit" : item.status} className={`${item.id === 6 || item.completed ? "hidden" : ""}`} value={item.status}>{item.status}</SelectItem>
                    )}
                  </Select>
                </ModalBody>
                <ModalFooter className="p-3 mt-4 justify-normal">
                  <div className="grid grid-cols-2 gap-4 w-full">
                    <Button
                      className="w-full bg-purpleBtn font-semibold text-white"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="w-full bg-lightBlue font-semibold text-white"
                    >
                      Update
                    </Button>
                  </div>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
      <ChatBox quoteId={orderData?.quote_id}></ChatBox>
    </div>
  );
};

export default OrderDetails;
