import React, { useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { Input } from "@nextui-org/react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../CustomButton";
import { resetPassSchema } from "./validationSchemas";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(resetPassSchema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/resetPassword/${params.token}`,
        data
      );
      toast.success("Password Reset Successfully ⭐");
      navigate("/login");
      reset();
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  return (
    <AuthLayout>
      <form className="xl:px-10" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="text-center">
          <h2 className="md:text-3xl text-2xl">Reset Password?</h2>
        </div>
        <div className="mt-16 mb-8 [&>*:not(:last-child)]:mb-5">
          <div>
            <Input
              {...register("password")}
              type={showPassword ? "text" : "password"}
              variant="bordered"
              label="New Password"
              labelPlacement="outside"
              placeholder=" "
              isRequired
              errorMessage={errors.password?.message}
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <AiOutlineEyeInvisible className="password_eye_icon" />
                  ) : (
                    <AiOutlineEye className="password_eye_icon" />
                  )}
                </button>
              }
            />
          </div>

          <div>
            <Input
              {...register("confirmPassword")}
              type={showConfirmPassword ? "text" : "password"}
              variant="bordered"
              label="Confirm Password"
              labelPlacement="outside"
              placeholder=" "
              isRequired
              errorMessage={errors.confirmPassword?.message}
              onPaste={(e) => {
                e.preventDefault();
              }}
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <AiOutlineEyeInvisible className="password_eye_icon" />
                  ) : (
                    <AiOutlineEye className="password_eye_icon" />
                  )}
                </button>
              }
            />
          </div>
        </div>
        <div>
          <CustomButton text="Reset" />
        </div>
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
