import * as yup from "yup";

const emailValidation = yup
  .string()
  .required("Email is required")
  .email("Invalid Email");

const passwordValidation = yup
  .string()
  .required("Password is required")
  .min(6, "Password must be at least 6 characters")
  .max(12, "Password must be at most 12 characters")
  .matches(
    /^(?=.*[!@#$%^&*()_+])[A-Z].*$/,
    "First letter must be capital and Need atleast one special character"
  );

const confirmPasswordValidation = yup
  .string()
  .required("Confirm Password is required")
  .oneOf([yup.ref("password"), null], "Passwords do not match");

const signupSchema = {
  role: yup.string().required("Please choose your role"),
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: emailValidation,
  phone: yup
    .number()
    .required()
    .typeError("Phone Number is required")
    .positive("Invalid Phone number")
    .integer("Invalid Phone number"),
  password: passwordValidation,
  confirm_password: confirmPasswordValidation,
  address_line_1: yup.string().required("Please enter your address"),
  state: yup.string().required("Please choose your state"),
  suburb: yup.string().required("Suburb is required"),
  postcode: yup
    .number()
    .required()
    .typeError("Post Code is required")
    .positive("Invalid Post Code")
    .integer("Invalid Post Code")
    .min(1000, "Post Code must be of 4 digits")
    .max(9999, "Post Code must be of 4 digits"),
};

// Login Schema
export const loginSchema = yup.object().shape({
  email: emailValidation,
  password: yup.string().required("Password is required"),
});

// Signup Schema (for Customer)
export const signupCustomerSchema = yup.object().shape({
  ...signupSchema,
});

// Signup Schema (for Supplier)
export const signupSupplierSchema = yup.object().shape({
  ...signupSchema,
  company_name: yup.string().required("Business Name is required"),
  abn_number: yup
    .string()
    .required("ABN is required")
    .matches(/^\d{11}$/, "ABN must be exactly 11 digits number"),
});

// Forgot Passsword Schema
export const forgotPassSchema = yup.object().shape({
  email: emailValidation,
});

// Reset Passsword Schema
export const resetPassSchema = yup.object().shape({
  password: passwordValidation,
  confirmPassword: confirmPasswordValidation,
});
