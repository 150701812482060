import React from "react";
import Notification from "./Notification";
import BreadCrumb from "./BreadCrumb";
import UserMenu from "./UserMenu";
import ChatNotification from "./ChatNotification";
 
const Header = () => {
  return (
    <div className="px-3 sm:px-6 py-4 flex justify-between items-center border-b-1 border-gray-200">
      <div>
        <BreadCrumb />
      </div>
      <div className="flex gap-3 items-center">
        <ChatNotification />
        <Notification />
        <UserMenu />
      </div>
    </div>
  );
};

export default Header;
