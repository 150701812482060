import React from "react";
import carImg from "../assets/images/car.png";
import Logo from "../assets/images/WreckonnectLogo2.png";

const AuthLayout = ({ children }) => {
  return (
    <div>
      <div>
        <div className="grid md:flex md:h-screen">
          <div
            className="bg-regalBlue text-white bg-no-repeat bg-blend-luminosity md:w-[50%] px-20 py-32 md:py-0 flex justify-center items-center"
            style={{
              backgroundImage: `url(${carImg})`,
              backgroundPositionY: "center",
              backgroundSize: "cover, 100%",
            }}
          >
            <div>
              <img src={Logo} alt="WreckConnect" />
            </div>
          </div>
          <div className="bg-white md:w-[50%] px-5 sm:px-28 md:px-5 lg:px-24 md:pt-14 py-10 -mt-10 rounded-[32px] md:rounded-none grid items-center md:overflow-y-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
