import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  Home,
  Login,
  SignUp,
  Enquiries,
  Users,
  Disputes,
  UserProfile,
  Administration,
  DisputeDetails,
  RequestDetails,
  QuoteGen,
  Quotes,
  ForgotPassword,
  ResetPassword,
  RequestGen,
  ProtectedRoutes,
  HelpFeedback,
  UpdateQuote,
  UpdateRequest,
  UpdateStatus,
  AcceptQuote,
  AcceptOrder,
  ChatBox,
  DisputeMessage,
  Orders,
  OrderDetails,
} from "./";
import About from "../components/AboutUs";
import UpdateProfile from "../pages/Admin/UpdateProfile";
import { DisputeUpdate } from "../pages/Admin/DisputeUpdate";
import { Success } from "../pages/Payment/Success";
import { Failed } from "../pages/Payment/Failed";
import { Report } from "../pages/Admin/Report";
import { Stripe } from "../pages/Payment/Stripe";

const PageRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup/:role" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route
        path="/"
        element={
          <ProtectedRoutes>
            <Home />
          </ProtectedRoutes>
        }
      >
        <Route
          path="requests"
          element={
            <ProtectedRoutes>
              <Enquiries />
            </ProtectedRoutes>
          }
        />
        <Route
          path="requestDetails/:id"
          element={
            <ProtectedRoutes>
              <RequestDetails />
            </ProtectedRoutes>
          }
        />
        <Route
          path="requests/:id"
          element={
            <ProtectedRoutes>
              <UpdateRequest />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/updateStatus/:id"
          element={
            <ProtectedRoutes>
              <UpdateStatus />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/accept-quote/:id"
          element={
            <ProtectedRoutes>
              <AcceptQuote />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/accept-order/:id"
          element={
            <ProtectedRoutes>
              <AcceptOrder />
            </ProtectedRoutes>
          }
        />
        <Route path="/requests/create" element={<RequestGen />} />
        <Route
          path="/request/generateQuote/:id"
          element={
            <ProtectedRoutes>
              <QuoteGen />
            </ProtectedRoutes>
          }
        />
        <Route
          path="users"
          element={
            <ProtectedRoutes>
              <Users />
            </ProtectedRoutes>
          }
        />
        <Route
          path="quotes"
          element={
            <ProtectedRoutes>
              <Quotes />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/quotes/:id"
          element={
            <ProtectedRoutes>
              <UpdateQuote />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/support"
          element={
            <ProtectedRoutes>
              <Disputes />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/dispute-details/:id"
          element={
            <ProtectedRoutes>
              <DisputeDetails />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/support/:id"
          element={
            <ProtectedRoutes>
              <DisputeMessage />
            </ProtectedRoutes>
          }
        />
        <Route
          path="user-profile"
          element={
            <ProtectedRoutes>
              <UserProfile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="updateprofile/:id"
          element={
            <ProtectedRoutes>
              <UpdateProfile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="help&feedback"
          element={
            <ProtectedRoutes>
              <HelpFeedback />
            </ProtectedRoutes>
          }
        />
        <Route
          path="administration"
          element={
            <ProtectedRoutes>
              <Administration />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/disputes/:id"
          element={
            <ProtectedRoutes>
              <DisputeUpdate />
            </ProtectedRoutes>
          }
        />
        <Route
          path="chatbox"
          element={
            <ProtectedRoutes>
              <ChatBox />
            </ProtectedRoutes>
          }
        />
        <Route
          path="orders"
          element={
            <ProtectedRoutes>
              <Orders />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/orders/:id"
          element={
            <ProtectedRoutes>
              <OrderDetails />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/about-us"
          element={
            <ProtectedRoutes>
              <About />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/payment-success"
          element={
            <ProtectedRoutes>
              <Success />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/payment-failed"
          element={
            <ProtectedRoutes>
              <Failed />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/stripe-connected"
          element={
            <ProtectedRoutes>
              <Stripe />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/report"
          element={
            <ProtectedRoutes>
              <Report />
            </ProtectedRoutes>
          }
        />
      </Route>
    </Routes>
  );
};

export default PageRoutes;
