import React from 'react';

const QuoteLayout = ({children, heading }) => {
  return (
    <div className='pt-5 pb-7 border-b-1 border-gray-100'>
      <div className='pb-5'>
        <p className='text-base sm:text-xl font-semibold'>{heading}</p>
      </div>
      <div className='w-full'>
        <div className={`block md:grid md:grid-cols-2 items-center gap-4`}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default QuoteLayout;
