import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Input,
  Select,
  SelectItem,
  Textarea,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Switch,
  Chip,
} from "@nextui-org/react";
import { IoAddSharp } from "react-icons/io5";
import { inputStyle, inputWrapperStyle } from "../../data/inputStyleData";
import { useNavigate, useParams } from "react-router-dom";
import UpdateLayout from "../../layouts/UpdateLayout";
import { chipValue } from "../../data/styleData";
import ChatBox from "../../components/ChatBox";
import { imageResizer } from "../../utils";
import { useForm, Controller } from "react-hook-form";
import AcceptOrder from "./AcceptOrder";
import { toast } from "react-toastify";
import axios from "axios";
import AddAssignee from "./AddAssignee";
import {
  getAllSalesRep,
  getAllPartType,
} from "../../store/slices/customerSlice";
import { getWarranty } from "../../store/slices/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import TransactionDetails from "../../components/TransactionDetails";

const UpdateQuote = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reps, part_type } = useSelector((state) => state.customer);
  const { warranty } = useSelector((state) => state.admin);
  const [quote, setQuote] = useState([]);
  const [images, setImages] = useState([]);
  const [request, setRequest] = useState([]);
  const [sales, setSales] = useState([]);

  const [warrantys, setWarrantyId] = useState("0");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: addAssignee,
    onOpen: sumAssignee,
    onClose: closeAssignee,
  } = useDisclosure();
  const { register, handleSubmit, watch, reset, control } = useForm();
  const [imageUpload, setImageUpload] = useState(new Set([]));

  let token = localStorage.getItem("accessToken");
  let headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${token}`,
    },
  };

  const uploadImage = async (event) => {
    setImages([]);
    for (const image of event.target.files) {
      imageResizer(image, setImages);
    }
  };

  const updateData = async (data) => {
    try {
      let total_data = {
        ...data,
        images: imageUpload,
      };
      // console.log(total_data);
      let res = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL_V2}/quotes/updatequote/${id}`,
        total_data,
        headers
      );
      toast.success(res.data.message);
      getQuoteById(id);
    } catch (error) {
      let err = error.response.data.message;
      toast.error(err);
    }
  };


  function recieptList() {
    let freight_price = parseFloat(watch("freight_price")) || 0;
    let part_price = parseFloat(watch("part_price")) || 0;
    let warranty_price = parseFloat(watch("warranty_price")) || 0;

    const recieptObj = {};
    const quantity = request?.quantity || 1; // Default quantity if not present
    const totalPrice = freight_price + part_price * quantity + warranty_price;
    const applyTax = watch("applyTax");
    const taxRate = 10 / 100; // 10% tax rate

    if (applyTax) {
      // Tax is applied separately
      recieptObj.totalTax = Number((totalPrice * taxRate).toFixed(2)); // Calculate tax
      recieptObj.freightPrice = freight_price; // Prices without tax
      recieptObj.partPrice = part_price;
      recieptObj.warrantyPrice = warranty_price;
      recieptObj.totalPrice = Number(
        (totalPrice + (totalPrice * 10) / 100).toFixed(2)
      ); // Total without tax
    } else {
      // Tax is already included in the price, deduct it from each
      recieptObj.totalTax = Number(
        (totalPrice - (totalPrice * 10) / 11).toFixed(2)
      ); // Calculate total tax
      recieptObj.freightPrice = Number(
        (freight_price / (1 + taxRate)).toFixed(2)
      ); // Deduct tax from each price
      recieptObj.partPrice = Number((part_price / (1 + taxRate)).toFixed(2));
      recieptObj.warrantyPrice = Number(
        (warranty_price / (1 + taxRate)).toFixed(2)
      );
      recieptObj.totalPrice = Number(totalPrice); // Total after tax deduction
    }

    return recieptObj;
  }

  const bill = recieptList();

  const getQuoteById = useCallback(async () => {
    try {
      let token = localStorage.getItem("accessToken");
      let headers = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/quotes/${id}`,
        headers
      );
      let data = res.data.details;
      setQuote(data?.quote);
      setRequest(data?.quote?.Request);
      setImages(data?.quote_images);
      if (data.quote?.status === 4) {
        toast.info("Your quote has been rejected.");
      } else if (data.quote?.status === 5) {
        toast.info("Your quote is discarded.");
      } else if (data.quote?.status === 6) {
        toast.info("You have canceled the quote.");
      } else if (data.quote?.status === 7) {
        toast.info("This quote has been dispute");
      }
    } catch (error) {
      let err = error.response.data.message;
      toast.error(err);
    }
  }, [id]);

  const cancelQuote = () => {
    handleCancel();
    onClose();
  };

  const handleCancel = async () => {
    try {
      let res = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/quotes/cancel/${id}`,
        "",
        headers
      );
      toast.success("Canceled the quote successfully.");
      setTimeout(() => {
        navigate("/quotes");
      }, 750);
    } catch (error) {
      let err = error?.response?.data?.message;
      console.log(error);
      toast.error(err);
    }
  };

  const getCommission = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/commssionrate/${id}`,
        " ",
        headers
      );
      // console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuoteById(id);
    dispatch(getAllSalesRep());
    dispatch(getAllPartType());
    dispatch(getWarranty());
    getCommission();
  }, []);

  useEffect(() => {
    const resetInputVal = () => {
      if (Object.keys(quote).length > 0) {
        const updateObject = {
          part_price: quote?.part_price,
          shipping_date: quote?.shipping_date?.split("T")[0],
          shipping_address: quote?.shipping_address,
          freight_price: quote?.freight_price,
          warranty: quote?.warranty,
          warranty_option: quote?.warranty_option,
          interchange_number: quote?.interchange_number,
          warranty_price: quote?.warranty_price,
          notes: quote?.notes,
          applyTax: quote.applyTax,
          sales_repId: quote?.sales_repId,
        };
        if (quote.QuoteOptions.length > 0) updateObject.options = [];
        const optionsArray = quote.QuoteOptions.map((option, index) => {
          return {
            id: option.id,
            part_type: option?.part_type,
            custom_part_type: option?.custom_part_type,
            quantity: option?.quantity,
            part_price: option?.part_price,
            freight_price: option?.freight_price,
            applyTax: option?.applyTax,
            notes: option?.notes,
            warranty: option?.warranty,
            warranty_option: option?.warranty_option,
            warranty_price: option?.warranty_price,
          };
        });
        if (optionsArray.length > 0) updateObject.options = optionsArray;
        // console.log("The updateObject is: ", updateObject);
        reset(updateObject);
        recieptList();
      }
      setWarrantyId(String(quote?.warranty));
      setSales(String(quote?.sales_repId));
    };
    resetInputVal();
  }, [quote, reset]);
  return (
    <div>
      <form onSubmit={handleSubmit(updateData)}>
        {quote?.status === 1 ||
        quote?.status === 2 ||
        quote?.status === 4 ||
        quote?.status === 5 ||
        quote?.status === 6 ||
        quote?.status === 7 ? (
          <div>
            <UpdateLayout
            heading={"Quote details"}
            title={"Update Quote:"}
            width={"80%"}
            images={images}
            status={chipValue[quote.status]}
            requestId={quote?.request_id}
            quoteId={quote?.id}
          >
            <div className="block sm:grid sm:grid-cols-2 gap-2 my-5">
              <Select
                variant="bordered"
                labelPlacement={"outside"}
                label="&nbsp;"
                placeholder="Select Sales Rep"
                selectedKeys={[sales]}
                className="max-w-xs"
                {...register("sales_repId", {
                  required: false,
                  valueAsNumber: true,
                })}
              >
                <SelectItem key="" onClick={() => sumAssignee()}>
                  <div className="flex gap-2 items-center">
                    <IoAddSharp style={{ fontSize: "0.9rem" }} />
                    <p>Add Sales Reps</p>
                  </div>
                </SelectItem>
                {reps.map((rep) => (
                  <SelectItem
                    key={rep.id}
                    value={rep.SalesRepName}
                    onClick={() => setSales(String(rep.id))}
                  >
                    {rep.SalesRepName}
                  </SelectItem>
                ))}
              </Select>
              <div className="flex justify-end">
                <Controller
                  name={`applyTax`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Switch
                        size="sm"
                        isSelected={field.value}
                        onChange={field.onChange}
                      >
                        Price exclusive of tax
                      </Switch>
                    );
                  }}
                />
              </div>
              <Input
                type="file"
                label="Upload images (Max 5 image)"
                classNames={{
                  input: inputStyle,
                  inputWrapper: inputWrapperStyle,
                }}
                className="mobile-only:mb-5"
                multiple
                accept="image/*"
                labelPlacement={"outside"}
                placeholder="Choose images"
                description={""}
                {...register("images", { required: false })}
                onChange={(e) => uploadImage(e)}
              />
              <Input
                type="date"
                label="Shipping Date"
                variant="bordered"
                labelPlacement={"outside"}
                placeholder="Enter date"
                description={""}
                className="w-full text-black mobile-only:mb-5"
                {...register("shipping_date", { required: false })}
              />

              <Select
                labelPlacement={"outside"}
                variant="bordered"
                className="w-full mobile-only:mb-5"
                label="Warranty Duration"
                selectedKeys={[String(warrantys)]}
                // id="duration"
                placeholder="warranty duration"
                {...register("warranty", { required: false })}
              >
                {warranty?.map((item) => {
                  const Value = `${item.duration} ${item.unit}`;
                  return (
                    <SelectItem
                      key={`${item.id}`}
                      value={item.id}
                      onClick={() => setWarrantyId(item.id)}
                    >
                      {Value}
                    </SelectItem>
                  );
                })}
              </Select>
              <Input
                type="number"
                label="Price AU$"
                placeholder="Price AU$"
                variant="bordered"
                labelPlacement={"outside"}
                className="mobile-only:mb-5"
                onWheel={(e) => e.target.blur()}
                description={""}
                {...register("part_price", {
                  required: false,
                  valueAsNumber: true,
                })}
              />
              <Input
                type="number"
                label="Freight Price"
                placeholder="Freight Price"
                variant="bordered"
                labelPlacement={"outside"}
                className="mobile-only:mb-5"
                description={""}
                onWheel={(e) => e.target.blur()}
                {...register("freight_price", {
                  required: false,
                  valueAsNumber: true,
                })}
              />
              <Input
                type="text"
                label="Warranty Option"
                placeholder="warranty option"
                variant="bordered"
                labelPlacement={"outside"}
                className="mobile-only:mb-5"
                description={""}
                {...register("warranty_option", { required: false })}
              />
              <Input
                type="number"
                label="Warranty Price $"
                placeholder="Warranty Price $"
                variant="bordered"
                className="mobile-only:mb-5"
                labelPlacement={"outside"}
                onWheel={(e) => e.target.blur()}
                description={""}
                {...register("warranty_price", {
                  required: false,
                  valueAsNumber: true,
                })}
              />
              <Input
                type="text"
                label="Interchange Number"
                placeholder="Interchange Number"
                variant="bordered"
                onWheel={(e) => e.target.blur()}
                className="mobile-only:mb-5"
                labelPlacement={"outside"}
                description={""}
                {...register("interchange_number", {
                  required: false,
                })}
              />
              

              <Textarea
                key={"bordered"}
                variant={"bordered"}
                maxRows={1}
                label="Notes"
                labelPlacement="outside"
                placeholder="Any message"
                className="col-span-2 "
                {...register("notes", { required: false })}
              />
            </div>
          </UpdateLayout>
          </div>
        ) : quote?.status === 3 || quote?.status === 8 ? (
          <AcceptOrder id={id} bill={bill} request={request}/>
        ) : (
           ""
        )}

        {/* ChatBox */}
        <ChatBox quoteId={id} />

        {quote?.status !== 3 && quote?.QuoteOptions?.length > 0 && (
          <div className="col-span-2 mt-5">
            <h1 className="font-semibold mt-2">Offer Additional Options: </h1>
            <div className="grid grid-cols-2 gap-4">
            
              {quote?.QuoteOptions?.map((option, index) =>
                option?.Part ? (
                  <OptionPartFields
                    option={option}
                    register={register}
                    index={index}
                    control={control}
                    watch={watch}
                  ></OptionPartFields>
                ) : (
                  <OptionCustomPartFields
                    option={option}
                    register={register}
                    index={index}
                    control={control}
                    watch={watch}
                  ></OptionCustomPartFields>
                )
              )}
              {quote?.QuoteOptions?.length%2 ===0  &&  <div></div>}
              <div className="w-full pt-14"><TransactionDetails request={request} bill={bill} /></div>
            </div>
          </div>
        )}

        {quote?.status !== 3 && quote?.QuoteOptions?.length === 0 && <div className="grid grid-cols-2 gap-4"><div></div><div><TransactionDetails request={request} bill={bill} /></div></div>}
        {quote?.status === 1 ||
        quote?.status === 2 ||
        quote?.status === 4 ||
        quote?.status === 5 ||
        quote?.status === 6 ||
        quote?.status === 7 ? <div className="grid grid-cols-2">
          <div></div>
            
            {quote.status === 1 || quote.status === 2 ? (
                <div className="flex mobile-only:flex-col justify-end gap-5 pb-10">
                  <Button
                    type="submit"
                    className=" bg-lightBlue text-white py-1 px-5 mt-6"
                  >
                    Update
                  </Button>
                  <Button
                    className=" bg-purpleBtn text-white py-1 px-5 mt-6"
                    onClick={() => onOpen()}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                " "
              )}
          </div>
        : <div></div>}
      </form>
      {/* modal for cancel request */}
      <Modal size={"xs"} isOpen={isOpen} onClose={onClose} placement="center">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 justify-center">
                Are you sure?
              </ModalHeader>
              <ModalBody>
                <div className="grid grid-cols-2 gap-2 pb-3">
                  <Button
                    className="border-2 border-orange-500 text-orange-500 bg-transparent hover:bg-orange-500 hover:text-white hover:border-none"
                    onPress={onClose}
                  >
                    No
                  </Button>
                  <Button
                    className="border-2 border-green-500 text-green-500 bg-transparent hover:bg-green-500 hover:text-white hover:border-none"
                    onClick={cancelQuote}
                  >
                    Yes
                  </Button>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* modal for add assignee */}
      <AddAssignee addAssignee={addAssignee} closeAssignee={closeAssignee} />
    </div>
  );
};

export default UpdateQuote;

const OptionPartFields = ({ option, index, register, control, watch }) => {
  const { part_type } = useSelector((state) => state.customer);
  const { warranty } = useSelector((state) => state.admin);
  const defaultPartType = String(watch(`options[${index}].part_type`));
  const defaultWarrentyId = String(watch(`options[${index}].warranty`))
  // console.log()
  // console.log("Warrenty is: ", warranty);
  return (
    <>
      <div className="block sm:grid sm:grid-cols-2 gap-3 mt-3">
        {/* <h1>Option Part Fields</h1> */}
        <div className="col-span-2 flex justify-end">
          <Controller
            name={`options[${index}].applyTax`}
            control={control}
            render={({ field }) => (
              <Switch
                size="sm"
                isSelected={field.value}
                onChange={field.onChange}
              >
                Price exclusive of tax
              </Switch>
            )}
          />
        </div>
        <Select
          labelPlacement={"outside"}
          variant="bordered"
          className="w-full mb-2 bg-white col-span-2 "
          label="Part Type"
          placeholder="Select part type"
          selectedKeys={[defaultPartType]}
          // defaultSelectedKeys={[option?.Part?.name]}
          {...register(`options[${index}].part_type`, {
            required: false,
            valueAsNumber: true
          })}
        >
          {part_type?.map(
            (item) =>
              item?.id !== 0 && (
                <SelectItem key={item.id} value={item.id}>
                  {item.name}
                </SelectItem>
              )
          )}
        </Select>

        <Input
          type="number"
          label="Quantity"
          variant="bordered"
          className="mb-2 "
          labelPlacement={"outside"}
          placeholder="0"
          onWheel={(e) => e.target.blur()}
          // value={`${option?.quantity}`}
          {...register(`options[${index}].quantity`, {
            valueAsNumber: true,
            required: false,
          })}
        />
        <Input
          type="number"
          label="Price AU$"
          variant="bordered"
          className="mb-2 "
          labelPlacement={"outside"}
          placeholder="0"
          onWheel={(e) => e.target.blur()}
          {...register(`options[${index}].part_price`, {
            required: false,
            valueAsNumber: true,
          })}
        />

        <Input
          type="number"
          label="Freight Price"
          variant="bordered"
          className="mb-2 "
          labelPlacement={"outside"}
          placeholder="0"
          onWheel={(e) => e.target.blur()}
          {...register(`options[${index}].freight_price`, {
            required: false,
            valueAsNumber: true,
          })}
        />
        <Select
          labelPlacement={"outside"}
          variant="bordered"
          className="w-full mobile-only:mb-5"
          label="Warranty Duration"
          selectedKeys={[defaultWarrentyId]}
          placeholder="warranty duration"
          {...register(`options[${index}].warranty`, {
            valueAsNumber: true,
          })}
        >
          {warranty?.map((item) => {
            const Value = `${item.duration} ${item.unit}`;
            return (
              <SelectItem key={item.id} value={item.id}>
                {Value}
              </SelectItem>
            );
          })}
        </Select>
        {option?.warranty_option && (
          <Input
            type="text"
            label="Warranty Option"
            variant="bordered"
            className="mb-2 "
            labelPlacement={"outside"}
            placeholder="Warranty option"
            // value={`${option?.warranty_option}`}
            {...register(`options[${index}].warranty_option`, {
              required: false,
            })}
          />
        )}
        {option?.warranty_price && (
          <Input
            type="number"
            label="Warranty Price"
            variant="bordered"
            className="mb-2 "
            labelPlacement={"outside"}
            placeholder="0"
            onWheel={(e) => e.target.blur()}
            {...register(`options[${index}].warranty_price`, {
              required: false,
              valueAsNumber: true,
            })}
          />
        )}
        <Textarea
          key={"bordered"}
          variant={"bordered"}
          label="Notes"
          labelPlacement="outside"
          placeholder="Any message"
          maxRows={1}
          className="col-span-2 "
          {...register(`options[${index}].notes`, { required: false })}
        />
      </div>
    </>
  );
};

const OptionCustomPartFields = ({ option, index, register, control, watch }) => {
  const { warranty } = useSelector((state) => state.admin);
  const defaultWarrentyId = String(watch(`options[${index}].warranty`))
  return (
    <>
      <div className="block sm:grid sm:grid-cols-2 gap-3 mt-3">
        {/* <h1>Custom Part Fields</h1> */}
        <div className="col-span-2 flex justify-end">
          <Controller
            name={`options[${index}].applyTax`}
            control={control}
            render={({ field }) => {
              // console.log("The field for custom component is: ", field);
              return (
                <Switch
                  size="sm"
                  isSelected={field.value}
                  onChange={field.onChange}
                >
                  Price exclusive of tax
                </Switch>
              );
            }}
          />
        </div>
        <Input
          type="text"
          label="Custom Part Type"
          variant="bordered"
          className="mb-2 col-span-2"
          labelPlacement={"outside"}
          placeholder="Custom Part Type"
          // value={`${option?.custom_part_type}`}
          {...register(`options[${index}].custom_part_type`, {
            required: false,
          })}
        />
        <Input
          type="number"
          label="Quantity"
          variant="bordered"
          className="mb-2"
          labelPlacement={"outside"}
          placeholder="0"
          onWheel={(e) => e.target.blur()}
          {...register(`options[${index}].quantity`, {
            required: false,
            valueAsNumber: true,
          })}
        />
        <Input
          type="number"
          label="Price AU$"
          variant="bordered"
          className="mb-2"
          labelPlacement={"outside"}
          placeholder="0"
          onWheel={(e) => e.target.blur()}
          {...register(`options[${index}].custom_part_type`, {
            required: false,
          })}
        />

        <Input
          type="number"
          label="Freight Price"
          variant="bordered"
          className="mb-2 "
          labelPlacement={"outside"}
          placeholder="0"
          onWheel={(e) => e.target.blur()}
          {...register(`options[${index}].freight_price`, {
            required: false,
            valueAsNumber: true,
          })}
        />
        <Select
          labelPlacement={"outside"}
          variant="bordered"
          className="w-full mobile-only:mb-5"
          label="Warranty Duration"
          selectedKeys={[defaultWarrentyId]}
          placeholder="warranty duration"
          {...register(`options[${index}].warranty`, {
            valueAsNumber: true,
          })}
        >
          {warranty?.map((item) => {
            const Value = `${item.duration} ${item.unit}`;
            return (
              <SelectItem key={item.id} value={item.id}>
                {Value}
              </SelectItem>
            );
          })}
        </Select>
        {option?.warranty_option && (
          <Input
            type="text"
            label="Warranty Option"
            variant="bordered"
            className="mb-2 "
            labelPlacement={"outside"}
            placeholder="Warranty option"
            // value={`${option?.warranty_option}`}
            {...register(`options[${index}].warranty_option`, {
              required: false,
            })}
          />
        )}
        {option?.warranty_price && (
          <Input
            type="number"
            label="Warranty Price"
            variant="bordered"
            className="mb-2 "
            labelPlacement={"outside"}
            placeholder="0"
            onWheel={(e) => e.target.blur()}
            {...register(`options[${index}].warranty_price`, {
              required: false,
              valueAsNumber: true,
            })}
          />
        )}
        <Textarea
          key={"bordered"}
          variant={"bordered"}
          label="Notes"
          maxRows={1}
          labelPlacement="outside"
          placeholder="Any message"
          className="col-span-2 "
          {...register(`options[${index}].notes`, { required: false })}
        />
      </div>
    </>
  );
};
