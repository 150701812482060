const columns = [
  { name: "Status", uid: "status", sortable: true },
  { name: "Industry", uid: "industry", sortable: true },
  { name: "Car", uid: "car", sortable: true },
  { name: "Part", uid: "part", sortable: true },
  { name: "Created", uid: "duration", sortable: true },
];

const statusOptions = [
  { name: "all", uid: "all" },
  { name: "Initiated", uid: "initiated" },
  { name: "Processing", uid: "processing" },
  { name: "Shipped", uid: "shipped" },
  { name: "In Transit", uid: "in Transit" },
  { name: "Completed", uid: "completed" },
  { name: "Delivered", uid: "delivered" },
  { name: "Dispute", uid: "dispute" },
  { name: "Cancelled", uid: "cancelled" },
];

export { columns, statusOptions };
