import React from "react";
import { Chip, Button } from "@nextui-org/react";
import ImageSlider from "../components/ImageSlider";
import { Link } from "react-router-dom";

export const styling = {
  color: {
    New: "primary",
    ["Awaiting Approval"]: "primary",
    ["In progress"]: "warning",
    ["In Progress"]: "warning",
    Accepted: "success",
    Rejected: "danger",
    Discarded: "danger",
    Cancelled: "danger",
    Dispute: "danger",
    "Order created": "success",
  },
  className: {
    New: "border-blue-500 text-blue-500",
    ["Awaiting Approval"]: "border-blue-500 text-blue-500",
    ["In progress"]: "border-orange-500 text-orange-500",
    ["In Progress"]: "border-orange-500 text-orange-500",
    Accepted: "border-green-500 text-green-500",
    Rejected: "border-red-500 text-red-500",
    Discarded: "border-red-500 text-red-500",
    Cancelled: "border-red-500 text-red-500",
    Dispute: "border-red-500 text-red-500",
    "Order created": "border-green-500 text-green-500",
  },
};

const UpdateLayout = ({
  children,
  heading,
  title,
  images,
  width,
  status,
  requestId,
  quoteId,
}) => {
  const role = localStorage.getItem("role");
  const handleClick = () => {
    localStorage.setItem("quote_id", quoteId);
  };

  return (
    <div className="px-0 sm:px-10">
      <div className="flex justify-between border-b-1 border-gray-200 mb-4 pb-2">
        <h1 className="text-lg font-semibold">{heading}</h1>
        {status && (
          <Chip
            color={styling.color[status]}
            variant="dot"
            className={`${styling.className[status]}`}
          >
            {status ==="In progress" ?"Awaiting Approval" : status}
          </Chip>
        )}
      </div>
      <div className="block md:grid md:grid-cols-2 gap-3">
        <div>
          {images?.length > 0 ? (
            <ImageSlider images={images} width={width} />
          ) : (
            <div className="flex justify-center pt-9 font-semibold">
              No image found
            </div>
          )}
          {role === "SUPPLIER" && (
            <div className="flex py-3">
              <Link
                to={`/requestDetails/${requestId}`}
                className="mobile-only:w-full"
              >
                <Button
                  className="bg-purpleBtn text-white font-semibold mobile-only:w-full"
                  onClick={handleClick}
                >
                  Check Request
                </Button>
              </Link>
            </div>
          )}
        </div>
        <div className="pt-5">
          <p className="font-semibold">{title}</p>
          {children}
        </div>
      </div>
    </div>
  );
};

export default UpdateLayout;
