import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  FaArrowRightFromBracket,
  FaAnglesRight,
  FaAnglesLeft,
} from "react-icons/fa6";
import { RiFileCopyLine, RiUserLine, RiBarChart2Line } from "react-icons/ri";
import { AiOutlineInfoCircle, AiOutlineShopping } from "react-icons/ai";
import logo from "../assets/images/WreckconnectLogo.png";
import { LuUsers2 } from "react-icons/lu";

const data = [
  {
    name: "Requests",
    link: "requests",
    icon: <FaArrowRightFromBracket />,
  },
  {
    name: "Quotes",
    link: "quotes",
    icon: <RiFileCopyLine />,
  },
  {
    name: "Orders",
    link: "orders",
    icon: <AiOutlineShopping />,
  },
  {
    name: "Request Credit",
    link: "support",
    icon: <AiOutlineInfoCircle />,
  },
  {
    name: "Administration",
    link: "administration",
    icon: <RiUserLine />,
  },
  // {
  //   name: "Support",
  //   link: "support",
  //   icon: <AiOutlineInfoCircle />,
  // },
  {
    name: "Users",
    link: "users",
    icon: <LuUsers2 />,
  },

  {
    name: "Reports",
    link: "report",
    icon: <RiBarChart2Line />,
  },
];

const Sidebar = ({ isOpen, toggleBar }) => {
  let role = localStorage.getItem("role");

  return (
    <div>
      <div>
        <div
          className={`py-4 px-6 bg-regalBlue text-white flex items-center gap-2 ${
            isOpen ? "justify-center" : "justify-between"
          }`}
        >
          <Link to="/requests" className={`${isOpen ? "hidden" : "block "}`}>
            <img src={logo} alt="wreckonnect" />
          </Link>
          <div className="text-[26px] cursor-pointer">
            <span onClick={toggleBar}>
              {isOpen ? <FaAnglesRight /> : <FaAnglesLeft />}
            </span>
          </div>
        </div>
        <div className="p-3">
          {data.map((item, index) => (
            <NavLink to={`${item.link}`} key={index}>
              <div
                className={`py-3 text-[0.85rem] mb-2 cursor-pointer font-semibold flex ${
                  isOpen ? "items-center justify-center " : "pl-4"
                } ${
                  (role === "CUSTOMER" || role === "SUPPLIER") && index > 3
                    ? "hidden"
                    : "block"
                } ${
                  (role === "ADMIN") && index < 4
                    ? "hidden"
                    : "block"
                } gap-3 items-center rounded-2xl hover:bg-regalBlue hover:text-white box-border`}
              >
                <span className="">{item.icon}</span>
                <span className={`${isOpen ? "hidden" : "block"} `}>
                  {item.name}
                </span>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
