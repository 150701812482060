import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import SettingsLayout from "../../layouts/SettingsLayout";
import {
  Button,
  Input,
  useDisclosure,
  Modal,
  ModalContent,
  ModalBody,
  Checkbox,
} from "@nextui-org/react";
import { inputStyle, inputWrapperStyle } from "../../data/inputStyleData";
import { useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa6";
import { User } from "@nextui-org/react";
import { getCommissionRate } from "../../store/slices/adminSlice";

const UpdateProfile = () => {
  const [userdata, setUserdata] = useState([]);
  const [img, setImg] = useState();
  const { id } = useParams();
  const [role, setRole] = useState("");
  const [userBlock, setUserBlock] = useState("Block");
  const accessToken = localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rate, setRate] = useState(null);
  const [value, setValue] = useState(false);
  const [commission, setCommission] = useState();
  const [block, setBlock] = useState();
  const {
    isOpen: addAssignee,
    onOpen: sumAssignee,
    onClose: closeAssignee,
  } = useDisclosure();

  const {
    isOpen: addModal,
    onOpen: sumModal,
    onClose: closeModal,
  } = useDisclosure();

  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/getuser?userId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setUserdata(res.data);
      setRole(res.data.details.role);
      setBlock(res.data.details.blocked);
    } catch (error) {
      console.log(error);
    }
  };

  const blockSupplier = async (block) => {
    try {
      let res = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/admin/block?userID=${id}`,
        {
          blocked: `${!block}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(res.data);
      setUserBlock("Unblock");
      if (userdata.details?.blocked === true) {
        toast.success("Unblock successfully ⭐");
        userDetails();
      } else {
        toast.success("Blocked successfully ⭐");
        userDetails();
      }
      setTimeout(() => {
        navigate("/users");
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpload = async (e) => {
    let img = e.target.files;
    try {
      Resizer.imageFileResizer(
        img[0],
        300,
        300,
        "JPEG",
        100,
        0,
        async(uri) => {
          let formData = new FormData();
          formData.append("file", uri); 
          let header = {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${accessToken}`,
            },
          };
          let res = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/admin/profile/upload/${id}`,
            formData,
            header
          );
          toast.success("Updated successfully ⭐");
          window.location.reload();
        },
        "file",
        200,
        200
      );
    } catch (err) {
      console.log(err);
    }
  };

  
  const handleCommission = (e) => {
    setRate(Number(e.target.value));
  };

  const getCommission = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/commssionrate/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setValue(true);
      setRate(res.data?.details?.commissionRate?.rate);
    } catch (error) {
      console.log(error);
      setValue(false);
    }
  };
  useEffect(() => {
    getCommission();
    setValue(false);
  }, []);

  const addModel = () => {
    sumAssignee();
  };

  const addCommissionModal = () => {
    sumModal();
  };
console.log(rate)
  const addCommission = async () => {
    let data = {
      rate: rate,
    };
    let header = {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      let res;
      if (value) {
        res = await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/admin/updatecommssionrate/${id}`,
          data,
          header
        );
        setRate(res.data.details.commissionRate?.rate);
        setCommission(res.data.details.commissionRate?.rate);
        toast.success("Updated successfully ⭐");
        closeAssignee();
      } else {
        res = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/admin/addcommssionrate/${id}`,
          data,
          header
        );
        setCommission(res.data.details.commissionRate?.rate);
        toast.success("Created successfully ⭐");
        closeAssignee();
      }
      dispatch(getCommissionRate());
    } catch (error) {
      const err = error.response.data.message;
      toast.error(err);
    }
  };
console.log(value)
  const updateUser = async (data) => {
    delete data.email;
    delete data.city;
    delete data.profile_id;

    try {
      let res = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/admin/updateuserdetail/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      toast.success("Updated successfully ⭐");
      navigate("/administration");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      userDetails();
    }
  }, [id]);

  useEffect(() => {
    if (userdata.details) {
      reset({
        first_name: userdata.details.first_name,
        last_name: userdata.details.last_name,
        phone: userdata.details.phone,
        email: userdata.details.email,
        address_line_1: userdata.details.address_line_1,
        address_line_2: userdata.details.address_line_2,
        country: userdata.details.country,
        state: userdata.details.state,
        city: userdata.details.suburb,
        postcode: userdata.details.postcode,
        profile_id: userdata.details.profile_id,
        business_name:userdata.details.business_name,
        Industry:userdata.details.Industry,
        capricorn_number:userdata.details.capricorn_number,
        company_name:userdata.details.company_name
      });
    }
  }, [userdata, reset]);

  return (
    <div className="px-2 sm:px-8 md:px-14">
      <div className="py-4 flex flex-col md:flex-row gap-4 md:gap-0 md:justify-between md:items-center">
        <div className="py-3 flex flex-col sm:flex-row items-center gap-4">
          {userdata.details?.profile_id ? (
            <img
              src={userdata.details.profile_id}
              alt="profile-pic"
              height="120px"
              width="120px"
              className="rounded-full"
            />
          ) : (
            <User
              as="button"
              avatarProps={{
                isBordered: false,
                src: img,
                showFallback: true,
                fallback: <FaUser fontSize={18} color="gray" />,
              }}
            />
          )}
          <div className="text-center sm:text-left">
            <p className="text-base font-semibold">
              <span>{userdata.details?.email}</span>
            </p>
            <p className="text-xs">{userdata?.details?.role}</p>
          </div>
        </div>
        <div>
          <Input
            type="file"
            label="Edit your profile image"
            className="mb-2"
            classNames={{
              input: [...inputStyle, "user-profile"],
              inputWrapper: inputWrapperStyle,
            }}
            accept="image/*"
            labelPlacement={"outside"}
            placeholder="Choose images"
            description={"( File should not be greater than 5MB )"}
            onChange={(e) => handleUpload(e)}
          />
        </div>
      </div>
      <form className="md:px-10 space-y-12" onSubmit={handleSubmit(updateUser)}>
        <SettingsLayout heading="Personal info">
          <div className="grid sm:grid-cols-2 gap-6 mb-4">
            <Input
              type="text"
              label="First Name"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("first_name", {
                required: "This field is required",
              })}
            />
            <Input
              type="text"
              label="Last Name"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("last_name", {
                required: "This field is required",
              })}
            />
            <Input
              type="number"
              label="Phone Number"
              labelPlacement="outside"
              variant="bordered"
              placeholder=" "
              onWheel={(e) => e.target.blur()}
              {...register("phone", {
                required: "This field is required",
              })}
            />
            <Input
              type="password"
              label="Password"
              labelPlacement="outside"
              variant="bordered"
              placeholder=" "
            />
            <Input
              type="email"
              label="Email"
              labelPlacement="outside"
              variant="bordered"
              placeholder=" "
              {...register("email", {
                required: "This field is required",
              })}
            />
          </div>
        </SettingsLayout>
        <SettingsLayout heading="Address info">
          <div className="grid sm:grid-cols-2 gap-6 ">
            <Input
              type="text"
              label="Address Line 1"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("address_line_1", {
                required: "This field is required",
              })}
            />
            <Input
              type="text"
              label="Address Line 2"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("address_line_2", {
                required: "This field is required",
              })}
            />

            <Input
              //   {...register("country")}
              type="text"
              variant="bordered"
              label="Country"
              labelPlacement="outside"
              placeholder=" "
              isReadOnly
              className="pointer-events-none"
              {...register("country", {
                required: false,
              })}
            />
            <Input
              //   {...register("country")}
              type="text"
              variant="bordered"
              label="State"
              labelPlacement="outside"
              placeholder=" "
              isReadOnly
              className="pointer-events-none"
              {...register("state", {
                required: false,
              })}
            />
            <Input
              type="text"
              label="City"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("city", {
                required: false,
              })}
            />
            <Input
              type="text"
              label="Post Code"
              labelPlacement="outside"
              classNames={{
                input: ["capitalize"],
              }}
              variant="bordered"
              placeholder=" "
              {...register("postcode", {
                required: false,
              })}
            />
          </div>
        </SettingsLayout>
        <SettingsLayout heading="Business info">
          <div className="grid sm:grid-cols-2 gap-6 ">
            {role === "SUPPLIER" ? (
              <Input
                type="text"
                label="Company Name"
                labelPlacement="outside"
                variant="bordered"
                isReadOnly
                placeholder=" " 
                {...register("company_name",{
                  required:false
                })}
              />
            ) : <Input
            type="text"
            label="Business Name"
            labelPlacement="outside"
            isReadOnly
            variant="bordered"
            placeholder=" " 
            {...register("business_name", {
              required: false,
            })}
          />}
            {role === "CUSTOMER" && (
              <Input
                type="text"
                label="Industry Type"
                labelPlacement="outside"
                variant="bordered"
                isReadOnly
                placeholder=" "
                {
                  ...register("Industry",{
                    required:false,
                  })
                }
              />
            )}
            <Input
                type="number"
                label="Capricorn Number"
                labelPlacement="outside"
                isReadOnly
                variant="bordered"
                placeholder=" "
                {
                  ...register("capricorn_number",{
                    required:false
                  })
                }
              />
            

            <Button
              onClick={() => addCommissionModal()}
              className="border bg-white text-blue-500 border-lightBlue mt-6  font-semibold mb-5  mobile-only:w-full"
            >
              Contract Info
            </Button>
            {role === "SUPPLIER" && (
              <Button
                onClick={() => addModel()}
                className="bg-lightBlue mt-6 text-white font-semibold mb-5 px-7 mobile-only:w-full"
              >
                {rate && rate > 0
                  ? "Check Commission Rate"
                  : "Add Commission Rate"}
              </Button>
            )}
          </div>
        </SettingsLayout>
        <SettingsLayout>
          <div>
            <Button
              className="bg-lightBlue text-white font-semibold mb-5 px-7 mobile-only:w-full"
              type="submit"
            >
              Update
            </Button>
            <Button
              className="bg-purpleBtn text-white font-semibold px-7 sm:ml-7 mobile-only:w-full"
              onClick={() => blockSupplier(block)}
            >
              {block === true ? "Unblock" : "Block"}
            </Button>
          </div>
        </SettingsLayout>
      </form>

      <Modal
        size={"xs"}
        isOpen={addAssignee}
        onClose={closeAssignee}
        placement="center"
      >
        <ModalContent>
          {(onClose) => (
            <ModalBody>
              <div className="flex gap-2 ">
                <Input
                  type="number"
                  className="my-2 "
                  variant="bordered"
                  label={"Enter Commission Rate"}
                  labelPlacement={"outside"}
                  placeholder="Enter commission rate"
                  value={rate}
                  onChange={handleCommission}
                />
              </div>
              <div className="grid grid-cols-2 gap-2 pb-3">
                <Button
                  className="border-2 border-orange-500 text-orange-500 bg-transparent hover:bg-orange-500 hover:text-white hover:border-none"
                  onClick={() => closeAssignee()}
                >
                  Cancel
                </Button>
                <Button
                  className="border-2 border-green-500 text-green-500 bg-transparent hover:bg-green-500 hover:text-white hover:border-none"
                  onClick={() => addCommission()}
                >
                  {rate && rate > 0 ? "Update" : "Add"}
                </Button>
              </div>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>

      <Modal
        size={"md"}
        isOpen={addModal}
        onClose={closeModal}
        placement="center"
      >
        <ModalContent>
          {(onClose) => (
            <ModalBody>
              <h1 className="text-center font-semibold text-2xl  text-gray-800 ">
                Contract Info
              </h1>
              <div className="h-[400px] px-2 overflow-y-scroll border border-gray-400 rounded-lg">
                <p className="text-xs mt-2">
                  By agreeing to the terms and conditions mentioned in this
                  document, you are bound to follow all the instructions as
                  mentioned below.
                </p>
                {userdata?.details?.role === "SUPPLIER" ? (
                  <div className="my-2 py-1">
                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      1. Supplier Agreement
                    </h2>

                    <p className="text-xs">
                      This agreement includes all the terms and conditions that
                      a supplier must follow to use the website smoothly. This
                      document consists of guidelines regarding the services and
                      rules. Suppliers should not engage in any misleading or
                      illegal activities.
                    </p>
                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      2. General Guidelines for Suppliers
                    </h2>
                    <p className="text-xs">
                      Suppliers must provide full, correct, true, and accurate
                      information regarding the products. The supplier is solely
                      responsible for the quality and quantity of the products
                      sold by them. Suppliers must not post any product or
                      information that is misleading to the customer or engage
                      in any illegal activity.
                    </p>

                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      3. Terms of Service on Wreckonnect
                    </h2>
                    <p className="text-xs">
                      By accepting the Terms of Service, you undertake that you
                      are accessing the website and transacting at your own
                      risk. We just provide the service and are not responsible
                      for any type of losses. If you are promising a warranty or
                      serving any service, then you must fulfill it. All
                      disputes must be resolved by you.
                    </p>

                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      4. What happens if you violates supplier rules
                    </h2>
                    <p className="text-xs">
                      If the supplier violates any rules or code of conduct, we
                      have the right to block the supplier's account and suspend
                      the process.
                    </p>
                  </div>
                ) : (
                  <div className="my-2 py-1">
                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      1. Collection of your info
                    </h2>

                    <p className="text-xs">
                      If you are using this website, you are responsible for
                      maintaining the confidentiality of your account and
                      password, as well as all activities that occur under your
                      account name. You must take all necessary steps to ensure
                      that your password is kept confidential and not shared
                      with anyone. If there is any misuse of your account,
                      please change your password immediately. Your information
                      is safe with us. Kindly update your email on time, as it
                      is important information that gives you a unique identity.
                      Please ensure that the details you provide to us are
                      accurate and updated, and do not provide any misleading
                      information.
                    </p>
                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      2. Disclaimer of Warranty
                    </h2>
                    <p className="text-xs">
                      Each product sold by the supplier comes with a warranty
                      duration, which may vary from supplier to supplier
                      according to the product. There is also an optional
                      warranty plan. Kindly check the warranty duration before
                      accepting the quote.
                    </p>

                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      3. Payment
                    </h2>
                    <p className="text-xs">
                      While processing payments, we are not responsible for any
                      type of refund. If any order is disputed or canceled for
                      any reason, the supplier will be liable to refund the
                      amount.
                    </p>

                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      4. Resolution of Disputes
                    </h2>
                    <p className="text-xs">
                      If disputes arise due to any defect in the product or
                      service, they will be handled carefully and a solution
                      will be provided. The disputes will be resolved promptly.
                    </p>

                    <h2 className="font-semibold text-md my-2 text-gray-700">
                      5. Platform for Transaction and Communication
                    </h2>
                    <p className="text-xs">
                      This website provides a platform only for transactions and
                      communication. It helps you to find the required parts
                      from the required seller. It establishes a connection
                      between the Supplier and Customer and does not involve any
                      physical interaction.
                    </p>
                  </div>
                )}
              </div>
              <div className="mt-1 mb-1">
                <Checkbox required size="sm" color="blue">
                  Accept Terms and Conditions
                </Checkbox>
              </div>
              <div className="grid grid-cols-4 gap-2 pb-3">
                <div></div>
                <div></div>

                <Button
                  className="border-2 h-8 border-orange-500 text-orange-500 bg-transparent"
                  onClick={()=>closeAssignee()}
                >
                  Cancel
                </Button>
                <Button className="border-2 h-8 border-green-500 text-green-500 bg-transparent  ">
                  Next
                </Button>
              </div>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default UpdateProfile;
