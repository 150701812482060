import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { STATUS } from "../constants";
import { getOrderDataById } from "./supplierSlice";
import axios from "axios";

const initialState = {
  user: {},
  makes: [],
  model: [],
  requests: [],
  singleRequest:[],
  firstRequest:[],
  quote:[],
  orderDetails:{},
  supplier_id: "",
  quote_images: [],
  request_images: [],
  part_type: [],
  orders:[],
  reps:[],
  status: STATUS.LOADING,
  error: "",
}; 

export const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    quoteById: (state, action) => {
      const { payload } = action;
     
      return {
        ...state,
        supplier_id: payload?.quote?.supplier_id,
        quote: payload?.quote,
        orderDetails:payload.quote?.orderDetails,
        singleRequest: payload?.quote?.Request,
        quote_images: payload.quote_images,
        request_images: payload.request_images
      }
    },
    allMake: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        makes: payload.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
      };
    },
    allMode: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        model: payload.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
      };
    },
    allPartType: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        part_type: payload.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
      };
    },
    allSalesRep: (state, action) => {
      const { payload } = action;
    

      return{
        ...state,
        reps: payload
        
      }
    },
    getAllRequests: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        requests: payload,
      };
    },
    getUser: (state, action) => {
      const { payload } = action;
      console.log(payload)
      return {
        ...state,
        user: payload.user,
      };
    },
    getOrders: (state, action) => {
      return {
        ...state,
        orders: action.payload,
        status:STATUS.IDLE
      }
    },
    changeStatus: (state, action) => {
      return {
        ...state,
        status: action.payload,
      };
    },
    isError: (state, action) => {
      return {
        ...state,
        error: action.payload,
      };
    },
  },
});

export const {
  quoteById,
  allMake,
  allMode,
  allPartType,
  allSalesRep,
  getAllRequests,
  getUser,
  changeStatus,
  isError,
} = customerSlice.actions;
export default customerSlice.reducer;

export const getMake = () => {
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/requests/makes/getAll`,
        header
      );
      let data = res.data?.details;
      dispatch(allMake(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response?.data?.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
};

export const getMode = (id) => {
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/requests/makes/model?make_ids=${id}`,
        header
      );
      let data = res.data.details;
      dispatch(allMode(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response?.data.message;
      dispatch(changeStatus(STATUS.ERROR));
      // dispatch(isError(err));
    }
  };
};

export const getAllSalesRep = () => {
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/suppliersalesreps/getallreps`,
        header
      );
      let data = res.data;
      dispatch(allSalesRep(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response.data.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  }
}

export const getAllPartType = () => {
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/requests/parts/getAll`,
        header
      );
      let data = res.data.details;
      dispatch(allPartType(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response?.data?.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
};

export const getUserDetails = () => {
  return async (dispatch) => {
    try {
      dispatch(changeStatus(STATUS.LOADING));
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/users/me`,
        header
      );
      let data = res.data.details;
      console.log( res.data.details)
      localStorage.setItem("role", data.user.role);
      dispatch(getUser(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response.data.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
};
 
export const getQuoteById = (id) => {
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/quotes/${id}`, header);
      // let res = await axios.get(`http://192.168.1.39:8082/api/v1/quotes/${id}`, header);
      let data = res.data?.details;
      // console.log(res.data)
      if(data?.quote?.orderDetails){
        let order_id = data?.quote?.orderDetails?.id;
        dispatch(getOrderDataById(order_id));
      }
      console.log(data)
      dispatch(quoteById(data));
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  }
}

export const getAllOrder = () => {
  return async (dispatch) => {
    try {
      // orders
      let token = localStorage.getItem("accessToken");
        let header = {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/orders`, header);
        console.log(res.data)
    } catch (error) {
      let err = error?.response?.data?.message;
      toast.error(err);
    }
  }
}

export const getImage=()=>{
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL_V2}/requests/create`,)

      
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  }
}
