import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../constants";
import axios from "axios";

const initialState = {
  accessToken: "",
  user: null,
  isLoggedIn: false,
  status: STATUS.LOADING,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addAuth: (state, action) => {
      localStorage.setItem('user_details', JSON.stringify(action.payload))
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true,
        status: STATUS.IDLE,
      };
    },

    loginSuccess: (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
      };
    },

    removeAuth: () => {
      localStorage.clear();
      window.location.href = "/login";
      return { ...initialState };
    },

    updateImage: (state, action) => {
      return {
        ...state,
        status: action.payload,
      };
    },
  },
});

export const { addAuth, removeAuth, updateImage, loginSuccess } =
  authSlice.actions;
export default authSlice.reducer;

export const updateProfileImg = (data) => {
  return async (dispatch) => {
    try {
      let formData = new FormData();
      formData.append("file", data); // Ensure 'file' is the name of your file input field
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/profile/upload`,
        formData,
        header
      );
      dispatch(updateImage(STATUS.IDLE));
    } catch (error) {
      dispatch(updateImage(STATUS.ERROR));
    }
  };
};
