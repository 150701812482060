import React, { useEffect, useState } from "react";
import { inputStyle, inputWrapperStyle } from "../../data/inputStyleData";
import { Button, Input, Textarea } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { imageResizer } from "../../utils";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";

const DisputeDetails = () => {
  const { register, handleSubmit, reset, formState: { errors }} = useForm();
  const reason_data = JSON.parse(localStorage.getItem("reason_data"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const token = localStorage.getItem("accessToken");
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const {id} = useParams();

  const uploadImage = async (event) => {
    setImages([]);
    for(const image of event.target.files) {
      imageResizer(image, setImages);
    }
  };

  const onSubmit = async (data) => {
    let total_data = {
      ...data,title:reason_data?.title, images:images, user_id:user_id
    };
    try {
      const header = {
        headers:{
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`
        }
      } 
      let res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/orders/${id}/dispute`, total_data, header);
      toast.success(res.data?.message);
      
      setTimeout(()=>{
        navigate("/quotes")
      }, 700);
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
      // console.log(error)
    }
  }
  
  useEffect(()=>{
    reset({
      title: reason_data?.titleReason
    })
  },[])


  return (
    <div className="sm:px-4 pt-2 pb-5">
      <div>
        <p className="font-semibold border-b-1 border-gray-300 pb-2 mb-5">Submit the details of order</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="sm:grid block sm:grid-cols-2 gap-5 mb-5">
            <div className="mb-5">
              <Input
                type="date"
                label="Recieved Order Date"
                variant="bordered"
                labelPlacement={"outside"}
                placeholder="Enter date"
                description={""}
                className="w-full text-black"
                {...register("received_date", { required: true })}
              />
              {errors.received_date && <p className="text-red-400 text-xs">Enter the receiving data</p>}
            </div>
            <div className="mb-5">
              <Input
                isReadOnly
                type="text"
                label="Enter Make"
                variant="bordered"
                labelPlacement={"outside"}
                placeholder="Enter Make"
                description={""}
                className="w-full text-black"
                value={reason_data.make ? reason_data.make :reason_data.custom_make}
              />
            </div>
            <div className="mb-5">
              <Input
                isReadOnly
                type="text"
                label="Enter Model"
                variant="bordered"
                labelPlacement={"outside"}
                placeholder="Enter Model"
                description={""}
                className="w-full text-black"
                value={reason_data.model ? reason_data.model: reason_data.custom_model}
              />
            </div>
            <div className="mb-5">
              <Input
                isReadOnly
                type="text"
                label="Enter Part"
                variant="bordered"
                labelPlacement={"outside"}
                placeholder="Enter Part Name"
                description={""}
                className="w-full text-black"
                value={reason_data.part}
              />
            </div>
            <div className="mb-5">
              <Input
                type="file"
                label="Upload images of Recieved product ( Max 5 Images )"
                className="text-black"
                classNames={{
                  input: inputStyle,
                  inputWrapper: inputWrapperStyle,
                }}
                multiple
                accept="image/*"
                labelPlacement={"outside"}
                placeholder="Choose images"
                description={""}
                {...register("images", { required: false })}
                onChange={(e) => uploadImage(e)}
              />
            </div>
          </div>
          <div>
            <p className="font-semibold border-b-1 border-gray-300 pb-2 mb-5">A brief note about reason</p>
            <Input
              type="text"
              label="Main reason"
              variant="bordered"
              labelPlacement={"outside"}
              placeholder="Enter reason"
              description={""}
              className="w-full text-black"
              {...register("title", { required: true })}
            />
            {errors.title && <p className="text-red-400 text-xs">Enter the reason</p>}
            <Textarea
              key={"bordered"}
              variant={"bordered"}
              label="Description"
              labelPlacement="outside"
              placeholder="Enter your description"
              className="col-span-12 md:col-span-6 mt-5 mb-6 md:mb-0"
              {...register("description", { required: true })}
            />
            {errors.description && <p className="text-red-400 text-xs">Enter detail description</p>}
          </div>
          <div className="flex justify-end">
            <Button type="submit" className="bg-lightBlue text-white py-1 px-10 mt-6">Submit</Button>
          </div>
        </form>
      </div>
    </div>
  

  );
};

export default DisputeDetails;
