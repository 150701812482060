export const chipColor = {
    "New": "primary",
    "In progress": "warning",
    "Accepted": "success",
    "Rejected": "danger"
}

export const quoteChipColor = {
    1: "primary",
    2: "warning",
    3: "success",
    4: "danger",
    5: "danger",
    6: "danger",
    7: "danger",
    8: "success"
}

export const chipStyle = {
    "New": "text-blue-400 border-blue-500",
    "In progress": "text-orange-400 border-orange-500",
    "Accepted": "text-green-400 border-green-500",
    "Rejected": "text-red-400 border-red-500"
}

export const requestStyle = {
    1 : "text-blue-400 border-blue-500",
    2 : "text-orange-400 border-orange-500",
    3 : "text-green-400 border-green-500",
    4 : "text-red-400 border-red-500",
    5 : "text-red-400 border-red-500",
    6 : "text-red-400 border-red-500",
    7 : "text-red-400 border-red-500",
    8 : "text-green-400 border-green-500"
}

export const chipValue = {
    1: "Awaiting Approval",
    2: "In Progress",
    3: "Accepted",
    4: "Rejected",
    5: "Discarded",
    6: "Cancelled",
    7: "Dispute",
    8: "Order Created"
}