import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { Button, Input } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addAuth } from "../../store/slices/authSlice";
import CustomButton from "../CustomButton";
import { loginSchema } from "./validationSchemas";
import { toast } from "react-toastify";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/login`,
        data
      );
      const responseData = response?.data.details;
      localStorage.setItem("user_details", JSON.stringify(responseData.user))
      localStorage.setItem("accessToken", responseData.accessToken);
      localStorage.setItem("refreshToken", responseData.refreshToken);
      localStorage.setItem("role", responseData.user.role);
      localStorage.setItem("user_id", responseData.user.id);
      dispatch(addAuth({ ...responseData }));
      if(responseData.user?.role==="ADMIN"){
        navigate("/administration");
      }
      else{
        navigate("/requests");
      }
      reset();
    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data.message);
    }
  };

  // preventing user from going back to Login page after successfull login
  useEffect(() => {
    let token = localStorage.getItem("accessToken");
    if (token) {
      navigate("/requests");
    }  
  }, [navigate]);

  return (
    <AuthLayout>
      <form className="xl:px-10" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="text-center">
          <h2 className="md:text-3xl text-2xl">Welcome Again!</h2>
        </div>
        <div className="mt-16 mb-8 [&>*:not(:last-child)]:mb-5">
          <div>
            <Input
              {...register("email")}
              type="email"
              variant="bordered"
              label="Email"
              labelPlacement="outside"
              placeholder=" "
              isRequired
              errorMessage={errors.email?.message}
            />
          </div>

          <div className="relative">
            <div className="absolute right-1 ">
              <Link to="/forgot-password" className="auth_link_2 text-xs text-lightBlue">
                Forgot Password?
              </Link>
            </div>
            <Input
              {...register("password")}
              type={showPassword ? "text" : "password"}
              variant="bordered"
              label="Password"
              labelPlacement="outside"
              placeholder=" "
              isRequired
              errorMessage={errors.password?.message}
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <AiOutlineEyeInvisible className="password_eye_icon" />
                  ) : (
                    <AiOutlineEye className="password_eye_icon" />
                  )}
                </button>
              }
            />
          </div>
        </div>
        <div>
          <CustomButton text="Login" />
        </div>
        <div className="text-sm text-center mt-12">
          <p>Don't have an account?</p>
          <div className="flex gap-2 items-center justify-center mt-1">
            <div className="w-12 border-[0.5px] border-purpleBt"></div>
            <div>Sign Up As</div>
            <div className="w-12 border-[0.5px] border-purpleBt"></div>
          </div>
          <div className="flex gap-3 justify-center mt-2">
            <Link to='/signup/CUSTOMER'><Button className="bg-transparent border-2 border-lightBlue text-lightBlue hover:bg-lightBlue hover:border-none hover:text-white px-4">CUSTOMER</Button></Link>
            <Link to='/signup/SUPPLIER'><Button className="bg-transparent border-2 border-lightBlue text-lightBlue hover:bg-lightBlue hover:border-none hover:text-white px-[22px]">SUPPLIER</Button></Link>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Login;
