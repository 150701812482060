import React, { useState } from "react";
import Logo from "../assets/images/WreckconnectLogo.png";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { Link, Outlet } from "react-router-dom";
import MobileNav from "../components/MobileNav";
import Footer from "../components/Footer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleBar = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <main>
      <div className="flex mb-14 md:mb-0">
        {/* Sidebar */}
        <div className={`${
              isOpen ? "w-[5%]" : "w-[20%]"
            } transition-width duration-700 border-r-1 border-gray-300 lg:block hidden relative min-h-screen`}>
          <div>
            <Sidebar isOpen={isOpen} toggleBar={toggleBar} />
            
          </div>
          <div className={`${!isOpen?"block":"hidden"} absolute bottom-0 w-full`}>
            <Footer />
          </div>
        </div>

        {/* Main-content */}
        <div className={`w-full ${isOpen ? "lg:w-[95%]" : "lg:w-[80%]"}`}>
          <div className="block lg:hidden bg-regalBlue py-5">
            <div>
              <Link to="/requests" className="flex justify-center"><img src={Logo} alt="website logo" className="w-2/4" /></Link>
            </div>
          </div>

          <Header />

          <div className="px-5 py-7">
            <Outlet />
            
          </div>
        </div>
      </div>
      <MobileNav />
    </main>
  );
};

export default Home;
