const columns = [
  { name: "ID", uid: "id", sortable: true },
  { name: "Status", uid: "status", sortable: true },
  { name: "Name", uid: "name", sortable: true },
  { name: "Email", uid: "email" },
  { name: "Role", uid: "role", sortable: true },
  { name: "Company", uid: "company", sortable: true },
];

const statusOptions = [
  { name: "Approved", uid: "approved" },
  { name: "Not Approved", uid: "not approved" },
];

export const faq = [
  {
    id: "1",
    qns: "How can I create a request for parts on your website?",
    ans: "To create a request, just go to the enquiry page and click the create request button. Simply fill all the details there, you can select any parts and click the create button. You can check your created request in enquiry page."
  },
  {
    id: "2",
    qns: "How can I create quote at any request on your website?",
    ans: "To create the quote, just go to the enquiry page and click any request on which you want to quote. A request details page will open, where you see all the details about request and customer. By clicking on create quote button, a form will open. Simply fill all the details and create the quote."
  },
  {
    id: "3",
    qns: "Is it possible to cancel the order?",
    ans: "Yes, you can cancel the order and also if you want to cancel the request. Just go to quotes page and select that quote which you want to cancel. Click on accepted button a page will open where you can see the option of dispute the order and cancel the order."
  },
  {
    id: "4",
    qns: "What if I am not satisfied with received product?",
    ans: "In this case, you can just dispute the order. For disputing the order, just go to that quote when click on accepted a page will open where you can see a option for dispute. when you click on that a page with form will open. You can just fill all the data and submit."
  }
];

export const warranty_duration = [
  {
    duration:"NA"
  },
  {
    duration: "10 days"
  },
  {
    duration: "20 days"
  },
  {
    duration: "30 days"
  },
  {
    duration: "2 months"
  },
  {
    duration: "3 months"
  },
  {
    duration: "4 months"
  },
  {
    duration: "5 months"
  },
  {
    duration: "6 months"
  },
  {
    duration: "7 months"
  },
  {
    duration: "8 months"
  },
  {
    duration: "9 months"
  },
  {
    duration: "10 months"
  },
  {
    duration: "11 months"
  },
  {
    duration: "12 months"
  }
]

const users = [
  {
    id: 1,
    name: "Tony Reichert",
    role: "Supplier",
    team: "Management",
    company: "Rival Inc.",
    status: "approved",
    age: "29",
    avatar: "https://i.pravatar.cc/150?u=a042581f4e29026024d",
    email: "tony.reichert@example.com",
  },
  {
    id: 2,
    name: "Zoey Lang",
    role: "Supplier",
    team: "Development",
    company: "Rival Inc.",
    status: "not approved",
    age: "25",
    avatar: "https://i.pravatar.cc/150?u=a042581f4e29026704d",
    email: "zoey.lang@example.com",
  },
  {
    id: 3,
    name: "Jane Fisher",
    role: "Supplier",
    team: "Development",
    company: "Rival Inc.",
    status: "approved",
    age: "22",
    avatar: "https://i.pravatar.cc/150?u=a04258114e29026702d",
    email: "jane.fisher@example.com",
  },
  {
    id: 4,
    name: "William Howard",
    role: "Supplier",
    team: "Marketing",
    company: "Rival Inc.",
    status: "not approved",
    age: "28",
    avatar: "https://i.pravatar.cc/150?u=a048581f4e29026701d",
    email: "william.howard@example.com",
  },
  {
    id: 5,
    name: "Kristen Copper",
    role: "Supplier",
    team: "Sales",
    company: "Rival Inc.",
    status: "approved",
    age: "24",
    avatar: "https://i.pravatar.cc/150?u=a092581d4ef9026700d",
    email: "kristen.cooper@example.com",
  },
  {
    id: 6,
    name: "Brian Kim",
    role: "Supplier",
    team: "Management",
    company: "Rival Inc.",
    age: "29",
    avatar: "https://i.pravatar.cc/150?u=a042581f4e29026024d",
    email: "brian.kim@example.com",
    status: "approved",
  },
  {
    id: 7,
    name: "Michael Hunt",
    role: "Supplier",
    team: "Design",
    company: "Rival Inc.",
    status: "not approved",
    age: "27",
    avatar: "https://i.pravatar.cc/150?u=a042581f4e29027007d",
    email: "michael.hunt@example.com",
  },
  {
    id: 8,
    name: "Samantha Brooks",
    role: "Supplier",
    team: "HR",
    company: "Rival Inc.",
    status: "approved",
    age: "31",
    avatar: "https://i.pravatar.cc/150?u=a042581f4e27027008d",
    email: "samantha.brooks@example.com",
  },
  {
    id: 9,
    name: "Frank Harrison",
    role: "Supplier",
    team: "Finance",
    company: "Rival Inc.",
    status: "not approved",
    age: "33",
    avatar: "https://i.pravatar.cc/150?img=4",
    email: "frank.harrison@example.com",
  },
  {
    id: 10,
    name: "Emma Adams",
    role: "Supplier",
    team: "Operations",
    company: "Rival Inc.",
    status: "approved",
    age: "35",
    avatar: "https://i.pravatar.cc/150?img=5",
    email: "emma.adams@example.com",
  },
  {
    id: 11,
    name: "Brandon Stevens",
    role: "Supplier",
    team: "Development",
    company: "Rival Inc.",
    status: "approved",
    age: "22",
    avatar: "https://i.pravatar.cc/150?img=8",
    email: "brandon.stevens@example.com",
  },
  {
    id: 12,
    name: "Megan Richards",
    role: "Supplier",
    team: "Product",
    company: "Rival Inc.",
    status: "not approved",
    age: "28",
    avatar: "https://i.pravatar.cc/150?img=10",
    email: "megan.richards@example.com",
  },
  {
    id: 13,
    name: "Oliver Scott",
    role: "Supplier",
    team: "Security",
    company: "Rival Inc.",
    status: "approved",
    age: "37",
    avatar: "https://i.pravatar.cc/150?img=12",
    email: "oliver.scott@example.com",
  },
  {
    id: 14,
    name: "Grace Allen",
    role: "Supplier",
    team: "Marketing",
    company: "Rival Inc.",
    status: "approved",
    age: "30",
    avatar: "https://i.pravatar.cc/150?img=16",
    email: "grace.allen@example.com",
  },
  {
    id: 15,
    name: "Noah Carter",
    role: "Supplier",
    team: "I. Technology",
    company: "Rival Inc.",
    status: "not approved",
    age: "31",
    avatar: "https://i.pravatar.cc/150?img=15",
    email: "noah.carter@example.com",
  },
  {
    id: 16,
    name: "Ava Perez",
    role: "Supplier",
    team: "Sales",
    company: "Rival Inc.",
    status: "approved",
    age: "29",
    avatar: "https://i.pravatar.cc/150?img=20",
    email: "ava.perez@example.com",
  },
  {
    id: 17,
    name: "Liam Johnson",
    role: "Supplier",
    team: "Analysis",
    company: "Rival Inc.",
    status: "approved",
    age: "28",
    avatar: "https://i.pravatar.cc/150?img=33",
    email: "liam.johnson@example.com",
  },
  {
    id: 18,
    name: "Sophia Taylor",
    role: "Supplier",
    team: "Testing",
    company: "Rival Inc.",
    status: "approved",
    age: "27",
    avatar: "https://i.pravatar.cc/150?img=29",
    email: "sophia.taylor@example.com",
  },
  {
    id: 19,
    name: "Lucas Harris",
    role: "Supplier",
    team: "Information Technology",
    company: "Rival Inc.",
    status: "not approved",
    age: "32",
    avatar: "https://i.pravatar.cc/150?img=50",
    email: "lucas.harris@example.com",
  },
  {
    id: 20,
    name: "Mia Robinson",
    role: "Supplier",
    team: "Operations",
    company: "Rival Inc.",
    status: "approved",
    age: "26",
    avatar: "https://i.pravatar.cc/150?img=45",
    email: "mia.robinson@example.com",
  },
];

export { columns, users, statusOptions };
