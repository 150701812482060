import React, { useEffect, useState } from "react";
import { Checkbox, Input, Select, SelectItem } from "@nextui-org/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import AuthLayout from "../../layouts/AuthLayout";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addAuth } from "../../store/slices/authSlice";
import { getStates } from "../../store/slices/countrySlice";
import { getSuppliertype } from "../../store/slices/adminSlice";
import { useForm } from "react-hook-form";
import CustomButton from "../CustomButton";
import { toast } from "react-toastify";

const SignUp = () => {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { states } = useSelector((state) => state.country);
  const { totalData } = useSelector((state) => state.form);
  const { suppliertype } = useSelector((state) => state.admin);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      country: "Australia",
    },
  });

  useEffect(() => {
    dispatch(getStates());
    dispatch(getSuppliertype());
  }, [selectedRole, reset, dispatch]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        // console.log('File content:', e.target.result); // Base64 encoded string
        resolve(e.target.result);
      };
      fileReader.readAsDataURL(file);

      fileReader.onerror = (error) => reject(error);
    });
  };

  const fileURL = localStorage.getItem("fileURL");

  const getForm = (totalData) => {
    const fileURL = localStorage.getItem("fileURL");
    const imageBlob = dataUrlToBlog(fileURL);
    const formData = new FormData();
    formData.append("image", imageBlob); // add image field to formData

    // To check the contents of the FormData, you can iterate over its entries
    // for (let [key, value] of formData.entries()) {
    //   console.log({ key, value})
    // }

    // now form data could be send to server
  };

  const dataUrlToBlog = (dataUrl) => {
    // Decode the Base64 string (ignoring the metadata part)
    const byteString = atob(dataUrl.split(",")[1]);

    // Extract the MIME type from the metadata
    const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

    // Create an ArrayBuffer with the same length as the byte string
    const ab = new ArrayBuffer(byteString.length);

    // Create a Uint8Array view of the ArrayBuffer
    const ia = new Uint8Array(ab);

    // Populate the Uint8Array with the decoded byte string
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the ArrayBuffer and specify the MIME type
    return new Blob([ab], { type: mimeString });
  };

  // submitting the form
  const onSubmit = async (data) => {
    // console.log(data);
    let formData = {
      ...data,
      phone: String(data.phone),
    };
    for (const key in formData) {
      if (key === "confirm_password") {
        delete formData[key];
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/register?type=${role}`,
        formData
      );
      const responseData = response?.data.details;

      localStorage.setItem("accessToken", responseData.accessToken);
      localStorage.setItem("refreshToken", responseData.refreshToken);
      localStorage.setItem("role", responseData.user.role);
      // console.log(totalData);
      dispatch(addAuth({ ...responseData }));

      if (Object.keys(totalData).length > 0) {
        let imageD = totalData.images.map((item) => {
          const imagedata = dataUrlToBlog(item);
          // console.log(imagedata);
          return imagedata;
        });
        let filesArr = [];
        filesArr.forEach((file, index) => {
          formData.append(`images_${index}`, file);
        });
        let res = await axios.post(
          `${process.env.REACT_APP_SERVER_URL_V2}/requests/create`,
          { ...totalData, images: imageD },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${responseData.accessToken}`,
            },
          }
        );
        toast.success("Created successfully ⭐");
      }
      navigate("/requests");
      // reset();
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("accessToken");
    if (token) {
      navigate("/requests");
    }
  }, [navigate]);

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="text-center">
          <h2 className="md:text-3xl text-2xl mb-1">New Here?</h2>
          {role === "SUPPLIER" ? (
            <p>Register quickly and start quotes on requested parts.</p>
          ) : (
            <p>Register quickly and start requesting part.</p>
          )}
        </div>
        <div className="mt-12 mb-8 [&>*:not(:last-child)]:mb-5">
          {role === "SUPPLIER" && (
            <>
              <div>
                <Input
                  {...register("company_name")}
                  type="text"
                  variant="bordered"
                  label="Name of Company"
                  labelPlacement="outside"
                  placeholder=" "
                  isRequired
                  errorMessage={errors.company_name?.message}
                />
              </div>
              <div>
                <Select
                  {...register("supplier_type")}
                  variant="bordered"
                  label="Supplier Type"
                  labelPlacement="outside"
                  placeholder=" "
                  isRequired
                  errorMessage={errors.supplier_type?.message}
                >
                  {suppliertype.map(
                    (item) =>
                      item?.id !== 0 && (
                        <SelectItem key={item.id}>{item.name}</SelectItem>
                      )
                  )}
                </Select>
              </div>
              <div>
                <Input
                  {...register("abn_number")}
                  type="number"
                  min="0"
                  variant="bordered"
                  label="ABN"
                  labelPlacement="outside"
                  onWheel={(e) => e.target.blur()}
                  placeholder=" "
                  isRequired
                  errorMessage={errors.abn_number?.message}
                />
              </div>
            </>
          )}
          
          {role === "CUSTOMER" && (
            <div>
              <Input
                {...register("business_name")}
                type="text"
                variant="bordered"
                label="Name of Business(If any)"
                labelPlacement="outside"
                placeholder=" "
                isRequired
                errorMessage={errors.business_name?.message}
              />
            </div>
          )}
          <div>
            <Input
              {...register("capricorn_number",{
                setValueAs: (value) => (value === "" ? null : parseFloat(value)),
              })}
              type="number"
              min="0"
              variant="bordered"
              label="Capricorn Number"
              labelPlacement="outside"
              onWheel={(e) => e.target.blur()}
              placeholder=" "
              // isRequired
              errorMessage={errors.capricorn_number?.message}
            />
          </div>
          <div className="grid sm:grid-cols-2 gap-5">
            <div>
              <Input
                {...register("first_name")}
                type="text"
                variant="bordered"
                label="First Name"
                labelPlacement="outside"
                placeholder=" "
                isRequired
                errorMessage={errors.first_name?.message}
                style={{ textTransform: "capitalize" }}
              />
            </div>

            <div>
              <Input
                {...register("last_name")}
                type="text"
                variant="bordered"
                label="Last Name"
                labelPlacement="outside"
                placeholder=" "
                isRequired
                errorMessage={errors.last_name?.message}
                style={{ textTransform: "capitalize" }}
              />
            </div>
          </div>

          <div>
            <Input
              {...register("email")}
              type="email"
              variant="bordered"
              label="Email"
              labelPlacement="outside"
              placeholder=" "
              isRequired
              errorMessage={errors.email?.message}
            />
          </div>

          <div>
            <Input
              {...register("phone")}
              type="number"
              variant="bordered"
              label="Phone Number"
              labelPlacement="outside"
              placeholder=" "
              isRequired
              errorMessage={errors.phone?.message}
            />
          </div>

          <div className="grid sm:grid-cols-2 gap-5">
            <div>
              <Input
                {...register("password")}
                type={showPassword ? "text" : "password"}
                variant="bordered"
                label="Password"
                labelPlacement="outside"
                placeholder=" "
                isRequired
                errorMessage={errors.password?.message}
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible className="password_eye_icon" />
                    ) : (
                      <AiOutlineEye className="password_eye_icon" />
                    )}
                  </button>
                }
              />
            </div>

            <div>
              <Input
                {...register("confirm_password")}
                type={showConfirmPassword ? "text" : "password"}
                variant="bordered"
                label="Confirm Password"
                labelPlacement="outside"
                placeholder=" "
                isRequired
                errorMessage={errors.confirm_password?.message}
                onPaste={(e) => {
                  e.preventDefault();
                }}
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <AiOutlineEyeInvisible className="password_eye_icon" />
                    ) : (
                      <AiOutlineEye className="password_eye_icon" />
                    )}
                  </button>
                }
              />
            </div>
          </div>

          <div className="grid sm:grid-cols-2 gap-5">
            <div>
              <Input
                {...register("address_line_1")}
                type="text"
                variant="bordered"
                label="Address Line 1"
                labelPlacement="outside"
                placeholder=" "
                isRequired
                errorMessage={errors.address_line_1?.message}
              />
            </div>

            <div>
              <Input
                {...register("address_line_2")}
                type="text"
                variant="bordered"
                label="Address Line 2"
                labelPlacement="outside"
                placeholder=" "
              />
            </div>
          </div>

          <div className="grid sm:grid-cols-2 gap-5">
            {role === "CUSTOMER" && (
              <div>
                <Select
                  {...register("Industry")}
                  variant="bordered"
                  label="Industry"
                  labelPlacement="outside"
                  placeholder=" "
                  isRequired
                  errorMessage={errors.Industry?.message}
                >
                  <SelectItem
                    key="Collision Repairer"
                    value="Collision Repairer"
                  >
                    Collision Repairer
                  </SelectItem>
                  <SelectItem key="Dismantler" value="Dismantler">
                    Dismantler
                  </SelectItem>
                  <SelectItem key="Mechanic" value="Mechanic">
                    Mechanic
                  </SelectItem>
                  <SelectItem key="Private" value="Private">
                    Private
                  </SelectItem>
                 
                  <SelectItem key="Reseller" value="Reseller">
                    Reseller
                  </SelectItem>
                </Select>
              </div>
            )}
            <div>
              <Input
                {...register("country")}
                type="text"
                variant="bordered"
                label="Country"
                labelPlacement="outside"
                placeholder=" "
                isReadOnly
                className="pointer-events-none"
              />
            </div>

            <div>
              <Select
                {...register("state")}
                variant="bordered"
                label="State"
                labelPlacement="outside"
                placeholder=" "
                isRequired
                errorMessage={errors.state?.message}
              >
                {states?.length > 0 ? (
                  states.map((item) => (
                    <SelectItem key={item.name}>{item.name}</SelectItem>
                  ))
                ) : (
                  <SelectItem key={"No data"}>Fetching the data...</SelectItem>
                )}
              </Select>
            </div>

            <div>
              <Input
                {...register("suburb")}
                type="text"
                variant="bordered"
                label="Suburb"
                labelPlacement="outside"
                placeholder=" "
                isRequired
                errorMessage={errors.suburb?.message}
              />
            </div>

            <div>
              <Input
                {...register("postcode")}
                type="number"
                variant="bordered"
                label="Post Code"
                labelPlacement="outside"
                onWheel={(e) => e.target.blur()}
                placeholder=" "
                isRequired
                errorMessage={errors.postcode?.message}
              />
            </div>
          </div>

          <div className="mt-8 mb-8">
            <Checkbox defaultSelected required size="sm" color="warning">
              Accept our{" "}
              <Link to="/" className="auth_link_2">
                Privacy Policy
              </Link>
            </Checkbox>
          </div>
          <div className="mt-5 mb-8">
            <Checkbox defaultSelected required size="sm" color="warning">
              Accept{" "}
              <Link to="/" className="auth_link_2">
                Terms and Conditions
              </Link>
            </Checkbox>
          </div>
        </div>
        <div>
          <CustomButton text="Sign Up" />
        </div>
        <div className="text-sm mt-8">
          {role === "CUSTOMER" && (
            <div
              className="flex justify-center items-center gap-2"
              onClick={() => {
                localStorage.setItem("firstCustomer", 1);
              }}
            >
              <p>Want to create a request?</p>
              <Link to="/requests/create" className="auth_link">
                Request
              </Link>
            </div>
          )}
        </div>
        <div className="text-sm mt-8">
          <div className="flex justify-center items-center gap-2">
            <p>Already have an account?</p>
            <Link to="/login" className="auth_link">
              Login
            </Link>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default SignUp;
