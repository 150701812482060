import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import customerSlice from "./slices/customerSlice";
import supplierSlice from "./slices/supplierSlice";
import countrySlice from "./slices/countrySlice";
import adminSlice from "./slices/adminSlice";
import formSlice from "./slices/formSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    customer: customerSlice,
    supplier: supplierSlice,
    country: countrySlice,
    admin:adminSlice,
    form: formSlice,
  },
});

export default store;
