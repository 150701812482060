const columns = [
  { name: "Status", uid: "status", sortable: true },
  { name: "Car", uid: "car", sortable: true },
  { name: "Part", uid: "part", sortable: true },
  { name: "Created", uid: "created", sortable: true },
  //   { name: "Role", uid: "role", sortable: true },
  //   { name: "Company", uid: "company", sortable: true },
];

export const dispute_reason = [
  {
    id:1,
    reason: "I am not satisfied with this."
  },
  {
    id: 2,
    reason: "This product is not working properly"
  },
  {
    id: 3,
    reason: "This product is very fragile condition"
  },
  {
    id: 4,
    reason: "Other reason"
  }
]

const statusOptions = [
  { name: "Approved", uid: "approved" },
  { name: "Not Approved", uid: "not approved" },
];
 
export { columns, statusOptions };
