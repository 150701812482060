import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate,useParams } from "react-router-dom";
import { addAuth } from "../store/slices/authSlice";
import { jwtDecode } from "jwt-decode";

const ProtectedRoutes = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const {role}  = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // handling auth user info
  useEffect(() => {
    if (accessToken) {
      (async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/users/me`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          dispatch(
            addAuth({
              ...response.data.details,
              accessToken,
              refreshToken,
            })
          );
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [dispatch, accessToken, refreshToken]);
 

  // handling auth token expiry
  useEffect(() => {
    if (accessToken) {
      const checkTokenExpiration = () => {
        const decoded = jwtDecode(accessToken);
        let currentDate = new Date();

        // JWT exp is in seconds
        if (decoded.exp * 1000 < currentDate.getTime()) {
          console.log("Token expired.");
          localStorage.clear();
          navigate("/login");
        }
      };

      checkTokenExpiration();
    }
  }, [accessToken, navigate]);

  return accessToken && role==="SUPPLIER" ?  <Navigate to="/login"/>  : children;
};

export default ProtectedRoutes;
