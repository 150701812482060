import React from 'react'
import { SlArrowRight } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";


export const Success = () => {
  const navigate =useNavigate();
  return (
    <>
    <div className='  border border-gray-100 shadow-md rounded-xl w-full p-4 flex flex-col items-center justify-center gap-2'>
        <div className='border-[1px] border-green-600 border-dashed   rounded-full p-3 my-2'>
        <IoMdCheckmarkCircleOutline style={{color:'green', fontSize:"90px"}}/>
        </div>
        <h1 className='text-3xl text-green-600 font-bold'>Payment Successful</h1>
        <p className='font-semibold text-gray-500'>Your order placed successfully</p>
        <p className='text-sm font-semibold text-gray-500'>View order details</p>

       <div className='flex mt-4'>
       <button
       onClick={()=>navigate('/orders')}
       className='text-xl text-green-600 font-semibold'>Continue </button><SlArrowRight style={{color: 'green' ,marginTop:'8px', marginLeft:"10px"}}/>
       </div>

    </div> 
    
   

    </>
  )
}
