import React, { useEffect, useState } from "react";
import { Select, SelectItem, Input } from "@nextui-org/react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import arrow from "../../assets/images/arrow.png"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getMake,
  getMode,
  getAllPartType,
} from "../../store/slices/customerSlice";
import { useDispatch, useSelector } from "react-redux";
// Register the components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const Report = () => {
  const { makes, model, part_type, reps } = useSelector(
    (state) => state.customer
  );
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const [make, setMake] = useState("");
  const [mode, setMode] = useState("");
  const [part, setPart] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [durationValue, setDurationValue] = useState();
  const [durationUnit, setDurationUnit] = useState();
  const [getCompany, setGetCompany] = useState([]);
  const [company, setCompany] = useState("")
  const [response, setResponse] = useState([]);
  const [labels, setLabels] = useState([]);
  const [orderValues, setOrderValues] = useState([]);
  const [quoteValues, setQuoteValues] = useState([]);
  const [approvalRate, setApprovalRate] = useState([]);
  const [averageTime, setAverageTime] = useState("");

  const getModelByMakeId = (id) => {
    dispatch(getMode(id));
  };


  const companyName = async () => {
    try {
      let res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/getcompany`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }
        })
      setGetCompany(res.data.details.suppliers)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    companyName();
  }, [])


  const handleCompany = (e) => {
    setCompany(e.target.value?.split("?")[1]);
  }



  const getRFData = async () => {
    try {
      let res;
      let baseURL = `${process.env.REACT_APP_SERVER_URL}/admin/pivotRFData?periodStart=${startDate}&periodEnd=${endDate}&make=${make}&model=${mode}&part_type=${part}`;
      if (durationUnit != null) {
        baseURL = `${process.env.REACT_APP_SERVER_URL}/admin/pivotRFData?periodStart=${startDate}&periodEnd=${endDate}&make=${make}&model=${mode}&part_type=${part}&duration=${durationValue}&durationUnit=${durationUnit}`;
      } else if (company !== undefined) {
        baseURL = `${process.env.REACT_APP_SERVER_URL}/admin/pivotRFData?periodStart=${startDate}&periodEnd=${endDate}&make=${make}&model=${mode}&part_type=${part}&company_name=${company}`
      }
      res = await axios.get(baseURL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setResponse(res.data);
      setLabels(res.data?.map((item) => item.date));
      setOrderValues(res.data?.map((item) => item.orderPercentage));
      setQuoteValues(res.data?.map((item) => item.quotePercentage));
      setApprovalRate(
        res.data?.map((item) =>
          item.quotePercentage
            ? (item.orderPercentage / item.quotePercentage) * 100
            : 0
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getAverageQuote = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/average-time-to-quote`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setAverageTime(res.data.averageTime)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRFData();
    getAverageQuote();
  }, [endDate, startDate, make, mode, part, company, durationUnit, durationValue]);

  const handleDuration = (e) => {
    setDurationUnit(e.anchorKey?.split(" ")[1]);
    setDurationValue(e.anchorKey?.split(" ")[0]);
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Quotes",
        data: quoteValues,
        borderColor: "lightBlue",
        backgroundColor: "white",

        fill: false,
        tension: 0.4,
      },
      {
        label: "Orders",
        data: orderValues,
        borderColor: "purple",
        backgroundColor: "white",
        fill: false,
        tension: 0.4,
      },
      {
        label: "Approval rate",
        data: approvalRate,
        borderColor: "orange",
        backgroundColor: "white",
        fill: false,
        tension: 0.4,
      },
    ],
  };


  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "black",
        },
      },
    },
  };


  useEffect(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const start = new Date(today);
    start.setDate(today.getDate() - dayOfWeek + 1);
    const end = new Date(start);
    end.setDate(start.getDate() + 6);
    setStartDate(start.toISOString().split("T")[0]);
    setEndDate(end.toISOString().split("T")[0]);
  }, []);

  useEffect(() => {
    dispatch(getMake());
    dispatch(getMode());
    dispatch(getAllPartType());
  }, [dispatch]);

  return (
    <div className="p-3">
      <div className="mb-6">
        <h2 className="mb-6 text-center font-semibold">Average Time To Quote</h2>
        <p className="font-semibold text-center text-sm mobile-only:text-xs ">{averageTime?.split(".")[0]} Hrs {averageTime?.split(".")[1]} min</p>
        <div className="mx-auto w-2/3 flex items-center justify-between mb-4 mobile-only:w-full">
          <span className="border-3 rounded-lg border-green-400 text-green-400 p-2 py-3 w-1/3 text-center font-semibold mobile-only:text-sm">Requests</span>
          <div>
            <img src={arrow} className="w-28 h-8 mobile-only:w-16" alt="" />
          </div>
          <span className="border-3 rounded-lg border-lightBlue p-2 py-3 w-1/3 text-center text-lightBlue font-semibold mobile-only:text-sm">Quotes</span>
        </div>
      </div>
      <div className="md:grid grid-cols-3 gap-2 mb-2 mt-4">
        <Input
          label="Start Date"
          placeholder="Enter date"
          labelPlacement={"outside"}
          type="date"
          variant="bordered"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <Input
          labelPlacement={"outside"}
          placeholder="Enter date"
          label="End Date"
          type="date"
          variant="bordered"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <Select
          labelPlacement={"outside"}
          placeholder="Select Duration"
          label="Duration"
          variant="bordered"
          onSelectionChange={handleDuration}
        >
          <SelectItem key="1 week" >1 week</SelectItem>
          <SelectItem key="2 week" >2 weeks</SelectItem>
          <SelectItem key="3 week" >3 weeks</SelectItem>
          <SelectItem key="4 week" >4 weeks</SelectItem>
          <SelectItem key="2 month">2 months </SelectItem>
          <SelectItem key="4 month">4 months</SelectItem>
          <SelectItem key="6 month">6 months</SelectItem>
          <SelectItem key="1 year">1 year</SelectItem>
          <SelectItem key="2 year">2 years</SelectItem>
          <SelectItem key="5 year">5 years</SelectItem>
        </Select>
      </div>

      <div className="md:grid grid-cols-3 gap-2 mb-4 ">
        <Select
          labelPlacement={"outside"}
          placeholder="Select make"
          variant="bordered"
          onChange={(e) => setMake(e.target.value)}
          label="Make"
        >
          {makes.map((item) => (
            <SelectItem
              key={item.id}
              value={item.id}
              onClick={() => getModelByMakeId(item.id)}
            >
              {item.name}
            </SelectItem>
          ))}
        </Select>
        <Select
          labelPlacement={"outside"}
          placeholder="Select model"
          variant="bordered"
          label="Model"
          onChange={(e) => setMode(e.target.value?.split(".")[1])}
        >
          {model.map((item) => (
            <SelectItem>{item.name}</SelectItem>
          ))}
        </Select>
        <Select
          label="Part Type"
          labelPlacement={"outside"}
          placeholder="Select part type"
          variant="bordered"
          onChange={(e) => setPart(e.target.value?.split(".")[1])}
        >
          {part_type.map((item) => (
            <SelectItem>{item.name}</SelectItem>
          ))}
        </Select>
        <Select
          label="Supplier's Company Name"
          labelPlacement={"outside"}
          placeholder="Select Company's Name"
          variant="bordered"
          // onChange={(e) => setCompany(e.target.value.split('?')[1])}
          onChange={(e) => setCompany(e.target.value?.split('.')[1])}
        // onSelectionChange={handleCompany}
        >
          {getCompany.map((e) => (
            <SelectItem>{e.company_name}</SelectItem>
          ))}
        </Select>
      </div>

      <div className="mt-6 h-[500px] mobile-only:h-[300px]">
        <Line data={data} options={options} />
      </div>

    </div>
  );
};
