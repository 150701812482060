import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  Chip,
  Pagination,
  Tabs,
  Tab,
  Divider,
  Spinner,
  Select,
  SelectItem,
} from "@nextui-org/react";
import { FiPlus, FiSearch } from "react-icons/fi";
import { columns, statusOptions } from "../data/enquiriesData";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStatus,
  getAllRequests,
  getMake,
  getMode,
} from "../store/slices/customerSlice";
import { STATUS } from "../store/constants";
import { displayDate, formatTime } from "../utils";
import axios from "axios";

// status colors
const statusColorMap = {
  new: "primary",
  // eslint-disable-next-line no-useless-computed-key
  ["in progress"]: "warning",
  ["awaiting approval"]: "warning",
  accepted: "success",
  dispute: "warning",
  rejected: "danger",
  discarded: "danger",
  cancelled: "warning",
};

// visible columns in the table
const INITIAL_VISIBLE_COLUMNS = [
  "status",
  "car",
  "industry",
  "part",
  "duration",
];

// main component
const Enquiries = () => {
  const dispatch = useDispatch();
  let role = localStorage.getItem("role");
  let user_role = JSON.parse(localStorage.getItem("user_details"))?.role;
  const accessToken = localStorage.getItem("accessToken");
  const { status, makes, model } = useSelector((state) => state.customer);
  const [visibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
  const [filterValue, setFilterValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [requests, setRequests] = useState([]);
  const [make, setMake] = useState([]);
  const [makeVal, setMakeVal] = useState([]);

  const [models, setModels] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [sortDescriptor, setSortDescriptor] = useState({
    column: "age",
    direction: "ascending",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");

  const fetchData = async (page, pageSize) => {
    try {
      dispatch(changeStatus(STATUS.LOADING));
      setRequests([]);
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/requests/?page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      let data = res.data.details?.rows;
      let index = res.data?.details?.index;
      data.sort((a, b) => {
        let date1 = new Date(a?.date_requested);
        let date2 = new Date(b?.date_requested);
        return date2 - date1;
      });
      setRequests(data);
      setTotalPages(index?.totalPages);
      setTotalRecords(index?.totalRecords);
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      dispatch(changeStatus(STATUS.ERROR));
      console.log(error);
    }
  };

  const getModelByMakeId = (id) => {
    dispatch(getMode(id));
  };

  // getting all requests
  useEffect(() => {
    fetchData(page, rowsPerPage);
    dispatch(getMake());
  }, [dispatch, accessToken, totalPages, page, rowsPerPage]);

  useEffect(() => {}, [requests, totalPages, status, model]);

  const hasSearchFilter = Boolean(filterValue) || Boolean(models);

  // columns to show
  const headerColumns = useMemo(() => {
    if (visibleColumns === "all") return columns;
    console.log(visibleColumns);
    return columns.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  // search & status filter
  const filteredItems = useMemo(() => {
    let filteredData = [...requests];
    // search-filter
    if (hasSearchFilter) {
      filteredData = filteredData.filter(
        (data) =>
          data.VehicleMake?.name
            .toLowerCase()
            .includes(filterValue?.toLowerCase()) ||
          data.VehicleModel?.name
            .toLowerCase()
            .startsWith(filterValue?.toLowerCase()) ||
          data.Part?.name.toLowerCase().includes(filterValue?.toLowerCase()) ||
          data.User?.first_name
            .toLowerCase()
            .includes(filterValue?.toLowerCase()) ||
          filterValue.split(",").map(Number).includes(data.make) ||
          (make.includes(data.make) &&
            filterValue
              .toLowerCase()
              .split(",")
              .includes(data.VehicleModel?.name.toLowerCase()))
      );
    }

    if (role === "SUPPLIER") {
      if (filterValue) {
        const customRequest = requests.filter((data) => data.custom_make);
        filteredData = [...filteredData, ...customRequest];
      }
    }

    // status-filter
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== statusOptions.length
    ) {
      filteredData = filteredData.filter((data) => {
        console.log(data?.custom_make);
        return statusFilter.includes(data?.RequestStatus.status.toLowerCase());
      });
    }
    return filteredData;
  }, [requests, page, hasSearchFilter, statusFilter, filterValue, model]);

  const pages = totalPages;

  const items = useMemo(() => {
    return filteredItems;
  }, [page, requests, filteredItems, rowsPerPage]);

  const changeModels = useCallback(
    (event) => {
      setModels(event.target.value);
    },
    [models]
  );

  // sorting
  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items, requests]);

  // table data cells
  const renderCell = useCallback(
    (data, columnKey) => {
      const cellValue = data[columnKey];
      
      switch (columnKey) {
        case "status":
          return (
            <Chip
              className="capitalize"
              color={statusColorMap[data?.RequestStatus?.status.toLowerCase()]}
              variant="flat"
            >
              {data?.RequestStatus?.status === "In progress" ? "Awaiting Approval" : data?.RequestStatus?.status}
            </Chip>
          );

        case "industry":
          return (
            role === "SUPPLIER" && (
              <div>
                <p className="table_cell">
                  {data.User?.Industry}
                </p>
              </div>
            )
          );

        case "car":
          return (
            <div className="flex flex-col gap-1">
              <p className="table_cell">
                {data.VehicleMake ? data.VehicleMake.name : data.custom_make}{" "}
                {data.VehicleModel != null
                  ? data.VehicleModel.name
                  : data.custom_model}
              </p>
              <p className="table_cell_sm">Year: {data.year}</p>
            </div>
          );

        case "part":
          return (
            <div>
              <p className="table_cell">
                {data.Part?.name != null
                  ? data.Part?.name
                  : data?.custom_part_type}
              </p>
            </div>
          );

        case "duration":
          return (
            <div className="flex flex-col gap-1">
              <p className="table_cell normal-case">
                {formatTime(data?.date_requested)}
              </p>
              <p className="table_cell_sm">
                {displayDate(data?.date_requested)}
              </p>
            </div>
          );

        default:
          return cellValue;
      }
    },
    [requests]
  );

  // rows per page
  const onRowsPerPageChange = useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
    fetchData(1, e.target.value);
  }, []);

  // searching
  const onSearchChange = useCallback(
    (value, type) => {
      if (value) {
        if (model) {
          setFilterValue((prev) => [...prev, value]);
        }
        setFilterValue(value);
      } else {
        setFilterValue("");
      }
    },
    [model]
  );

  const getModelOfMake = (id) => {
    if (!make.includes(id)) {
      setMake((prev) => [...prev, id]);
    }
  };

  const getData = (event) => {
    const makeValue = [...event];
    getModelByMakeId(makeValue.toString());
  };

  // clearing the search

  const onClear = useCallback(() => {
    setFilterValue("");
  }, []);

  // top content
  const topContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-6">
        <div className="flex justify-between gap-3 items-end w-full">
          {role === "CUSTOMER" ? (
            <Input
              isClearable
              className="w-full sm:max-w-[44%]"
              placeholder="Search requests..."
              startContent={<FiSearch />}
              value={filterValue}
              onClear={() => onClear()}
              onValueChange={onSearchChange}
            />
          ) : (
            <div className="md:grid grid-cols-2 gap-1">
              <Select
                labelPlacement={"outside"}
                placeholder="Select make"
                variant="bordered"
                selectionMode="multiple"
                className="w-full sm:w-[220px] outline-none"
                label="Make"
                onChange={(event) =>
                  onSearchChange(event.target.value, "model")
                }
                onSelectionChange={getData}
              >
                {makes.map(
                  (item) =>
                    item?.id !== 0 && (
                      <SelectItem
                        key={item.id}
                        value={item.id}
                        onClick={() => getModelOfMake(item.id)}
                      >
                        {item.name}
                      </SelectItem>
                    )
                )}
              </Select>
              <Select
                multiple
                labelPlacement={"outside"}
                placeholder="Select model"
                variant="bordered"
                selectionMode="multiple"
                className="w-full sm:w-[220px] outline-none"
                disabledKeys={["null"]}
                label="Model"
                onChange={(event) => onSearchChange(event.target.value)}
              >
                {
                  model.map(
                    (item) =>
                      item?.id !== 0 && (
                        <SelectItem key={item.name}>{item.name}</SelectItem>
                      )
                  )
                 
                }
              </Select>
            </div>
          )}

          {role === "CUSTOMER" && (
            <div>
              <Button
                endContent={
                  <FiPlus color="white" className="text-[0.65rem] sm:text-lg" />
                }
                className="bg-lightBlue text-white text-[0.65rem] sm:text-base"
              >
                <Link to="/requests/create">Create Request</Link>
              </Button>
              &nbsp;
            </div>
          )}
        </div>

        <div className="flex flex-col-reverse sm:flex-row flex-wrap items-center justify-between gap-y-4">
          <div>
            <Tabs
              aria-label="Options"
              variant="underlined"
              selectedKey={statusFilter}
              onSelectionChange={setStatusFilter}
            >
              <Tab key="all" title="All requests"></Tab>
              <Tab key="new" title="New requests"></Tab>
              <Tab key="in progress" title="Awaiting Approval"></Tab>
            </Tabs>
          </div>

          <div className="flex items-center gap-5 text-xs sm:text-sm text-default-400">
            <span>Total: {totalRecords} requests</span>
            <Divider orientation="vertical" className="h-6" />
            <label className="flex items-center">
              Rows per page:
              <select
                className="bg-transparent outline-none"
                onChange={onRowsPerPageChange}
              >
                <option value="6">6</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </label>
          </div>
        </div>
      </div>
    );
  }, [
    filterValue,
    onSearchChange,
    role,
    make,
    makeVal,
    statusFilter,
    requests.length,
    onRowsPerPageChange,
    onClear,
  ]);

  // bottom content
  const bottomContent = useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-center items-center">
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          initialPage={page}
          total={pages === 0 ? pages + 1 : pages}
          onChange={setPage}
        />
      </div>
    );
  }, [page, pages]);
  console.log(headerColumns)
  return (
    <Table
      aria-label="Request Table"
      isHeaderSticky
      isStriped
      topContent={topContent}
      topContentPlacement="outside"
      bottomContent={bottomContent}
      bottomContentPlacement="outside"
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
      classNames={{
        td: "whitespace-nowrap",
      }}
    >
      <TableHeader columns={headerColumns}>
        {(column) => (
          <TableColumn
            key={column.uid}
            align={column.uid === "actions" ? "center" : "start"}
            allowsSorting={column.sortable}
          >
            {role === "CUSTOMER" && column.name === "Industry"
              ? ""
              : column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        emptyContent={"No requests found"}
        items={sortedItems}
        isLoading={status === STATUS.LOADING && !requests.length}
        loadingContent={
          <Spinner label="Loading..." className="w-full bg-white" />
        }
      >
        {(item) => (
          <TableRow key={item.id}>
            {(columnKey) => (
              <TableCell>
                {role === "SUPPLIER" ? (
                  <Link to={`/requestDetails/${item.id}`}>
                    {renderCell(item, columnKey)}
                  </Link>
                ) : (
                  <Link to={`/requests/${item.id}`}>
                    {renderCell(item, columnKey)}
                  </Link>
                )}
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default Enquiries;
