import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper/modules";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css";

const ImageSlider = ({images,width}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <div  className='sm:p-4 w-full '>
        <Swiper
            spaceBetween={25}
            slidesPerView={1}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[Thumbs]}
            className="mySwiper2 mb-2 "
        >
            {images?.map((item)=>
                <SwiperSlide key={item.id}>
                    <img src={item.url} alt="slider" style={{width:width}} width="100%" height="100%" />
                </SwiperSlide>
            )}
        </Swiper>
        <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={1}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[Thumbs]}
            className="mySwiper1"
        >
            {images?.map((item)=>
                <SwiperSlide key={item.id}>
                 
                    <img src={item.url} alt="slider" width="100%" height="100%" />
                </SwiperSlide>
            )}
        </Swiper>
    </div>
  )
}

export default ImageSlider;
