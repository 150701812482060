import React, { useState, useEffect } from "react";
import { Input, Chip, Modal, ModalContent, ModalBody, ModalFooter, useDisclosure, Button, Tooltip ,Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { quoteChipColor, requestStyle, chipValue } from "../../data/styleData";
import { IoInformationCircle } from "react-icons/io5";
import { useFieldArray, Controller, useForm } from "react-hook-form";
import Capricorn_logo from "../../assets/images/Capricorn_logo.png"
import { getQuoteById } from "../../store/slices/customerSlice";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ImageSlider from "../../components/ImageSlider";
import ChatBox from "../../components/ChatBox";
import { toast } from "react-toastify";
import axios from "axios";

const quotessss = [
  {
    partType: 'Air Filter',
    quantity: 1,
    price: '$100',
    freightPrice: '$10',
    warranty: '20 Days ($0)',
    totalPrice: '$110',
  },
  {
    partType: 'Air Filer',
    quantity: 1,
    price: '$100',
    freightPrice: '$10',
    warranty: '6 Months ($20)',
    totalPrice: '$130',
  },
];

const UpdateStatus = () => {
  const { supplier_id, singleRequest, quote_images } = useSelector(
    (state) => state.customer
  );
  const {
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      parts_name: [{ part_id: 0, quantity: 0 }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "parts_name",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [supplier, setSupplier] = useState([]);
  const [approval, setApproval] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quote, setQuote] = useState({});
  const { id } = useParams();

  const getSupplierInfo = async (id) => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/users/profile/${id}`
      );
      let data = res.data?.details?.user;
      setSupplier(data);
    } catch (error) {
      let err = error.response?.data.message;
      toast.error(err);
    }
  };

  const getQuote = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/quotes/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuote(res.data.details?.quote);
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  };


  
  const getApprovalOffer = async (option) => {
    try {
      const token = localStorage.getItem("accessToken");
      let res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/quotes/approve/${id}`,
        {
          option_approvals: [
            {
              option_id: option?.id,
              approval: !option?.approval,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getQuote();
    } catch (error) {
      let err = error.response.data.message;
      toast.error(err);
    }
  };
  
  const handleApprove = () => {
    updateStatus("Accepted");
  };
  
  const handleReject = () => {
    updateStatus("Rejected");
    onClose();
  };
  
  const updateStatus = async (status) => {
    try {
      const token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let data = {
        status: status,
      };
      let res = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/requests/quote/update/status/${id}`,
        data,
        header
      );
      toast.success(res?.data?.message);
      if (status === "Accepted") {
        setApproval("accept");
        setTimeout(() => {
          navigate(`/accept-quote/${id}`);
        }, 1500);
      } else if (status === "Rejected") {
        setApproval("reject");
      }
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  };
  
  const handleNavigate = () => {
    setTimeout(() => {
      navigate(`/accept-quote/${id}`);
    }, 500);
  };
  
  const totalPriceWithTax = (value) => {
    let total_val = (value * (10 / 100)).toFixed(2);
    return Number(total_val) + value;
  };
  
  useEffect(() => {
    dispatch(getQuoteById(id));
    getQuote();
    if (supplier_id) {
      getSupplierInfo(supplier_id);
    }
  }, [dispatch, id, supplier_id]);
  
  useEffect(() => {
    if (supplier_id) {
      getSupplierInfo(supplier_id);
    }
  }, [supplier_id, singleRequest, quote_images]);
  
  // console.log("The quote status is:",quote);
  
  return (
    <div>
      <p className="border-b-1 border-gray-200 pb-1 font-semibold mb-7">
        Update Status
      </p>
      <div className="grid gap-1 md:grid-cols-2 w-full h-auto md:h-auto sm:!h-auto border-box">
        <div className="h-[440px] overflow-hidden w-full">
          <div className="m-4 mr-10 mb-20 overflow-hidden">
            {quote_images.length > 0 ? (
              <ImageSlider images={quote_images} width={"80%"}/>
            ) : (
              <div className="flex justify-center items-center w-full min-h-[200px]">
                {" "}
                <p>No images found</p>
              </div>
            )}
          </div>
        </div>
        <div>
          <p className="font-semibold mb-5">Quote details</p>
          <div>
            <div className="flex gap-2 items-center justify-between mb-7">
              <div className="flex gap-3 items-center">
                <img
                  src={supplier?.profile_id}
                  alt="supplier avatar"
                  className="rounded-full"
                  width="50px"
                  height="50px"
                />
                <div>
                  <p className="capitalize text-sm">
                    {supplier?.first_name + " " + supplier?.last_name} 
                  </p>
                  <p className="text-xs font-[300]">SUPPLIER</p>
                </div>
              </div>
             <div className="flex items-center gap-2">
              {supplier?.capricorn_number && <img src={Capricorn_logo} alt="" className="w-[28px] h-[28px]"/>}
             <Chip
                color={quoteChipColor[quote.status]}
                variant="dot"
                className={requestStyle[quote.status]}
              >
                {chipValue[quote.status]}
              </Chip>
              
             </div>
            </div>
            <div className="gap-6 details-style justify-around">
              <div>
                <p>Email</p>
                <p>{supplier?.email}</p>
              </div>
              <div>
                <p>Phone Number</p>
                <p>{supplier?.phone}</p>
              </div>
              <div>
                <p>Company Name</p>
                <p>{supplier?.company_name}</p>
              </div>
            </div>

            <div className="details-style gap-2 justify-between">
              <div>
                <p>Vehicle Make</p>
                <p>
                  {singleRequest?.VehicleMake?.name
                    ? singleRequest?.VehicleMake?.name
                    : singleRequest?.custom_make}
                </p>
              </div>
              <div>
                <p>Vehicle Model</p>
                <p>
                  {singleRequest?.VehicleModel?.name
                    ? singleRequest?.VehicleModel?.name
                    : singleRequest?.custom_model}
                </p>
              </div>
              <div>
                <p>Part</p>
                <p>
                  {singleRequest?.Part
                    ? singleRequest?.Part?.name
                    : singleRequest?.custom_part_type}
                </p>
              </div>
              <div>
                <p>Quantity</p>
                <p>{singleRequest?.quantity}</p>
              </div>
            </div>
            <div className="details-style gap-2 justify-between">
              <div>
                <p>Rego</p>
                <p>{singleRequest?.rego}</p>
              </div>
              <div>
                <p>Fuel Type</p>
                <p>{singleRequest?.fuelType?.name}</p>
              </div>
              <div>
                <p>Transmission</p>
                <p>{singleRequest?.transmissionModel?.name}</p>
              </div>
              <div>
                <p>Body Style</p>
                <p>{singleRequest?.bodyStyle?.name}</p>
              </div>
            </div>
            {/* <div className="flex gap-3 mb-5 items-center">
              <p className="text-sm font-semibold">Interchange Number:</p>
              <p className="text-xs">
                {singleRequest?.interchange_number
                  ? singleRequest?.interchange_number
                  : "Not Provided"}
              </p>
            </div> */}
            
           
            {/* ------ quote details ------ */}
            <div className="block sm:grid sm:grid-cols-2 gap-3">
              <Input
                type="text"
                label="Shipping Date"
                variant="bordered"
                className="mb-2"
                labelPlacement={"outside"}
                placeholder="Any message"
                value={`${quote?.shipping_date?.split("T")[0]}`}
                description={""}
              />
              <Input
                type="text"
                label="Sales Representative"
                variant="bordered"
                className="mb-2 "
                labelPlacement={"outside"}
                placeholder="Sales Rep"
                value={`${quote?.SupplierSalesRep?.SalesRepName}`}
                description={""}
              />
            </div>
          </div>
          <div className="flex w-full justify-end h-[40px]">
          {quote.status === 1 || quote.status === 2 ? (
            <div
              className={`grid  ${
                approval ? "" : "grid-cols1"
              } gap-3 mb-5 mt-5`}
            >

              {approval === "accept" ? (
                ""
              ) : (
                <Button
                  className="bg-purpleBtn text-white font-semibold"
                  onPress={approval === "reject" ? "" : () => onOpen()}
                >
                  {approval === "reject" ? "Rejected" : "Reject"}
                </Button>
              )}
            </div>
          ) : quote?.status === 3 || quote?.status === 8 ? (
            <Button
              className="bg-lightBlue mt-4 mb-32 float-right text-white font-semibold mobile-only:w-full mobile-only:mt-3"
              onClick={handleNavigate}
            >
              {" "}
              Check Status
            </Button>
          ) : quote?.status === 4 ? (
            <Button className="bg-purpleBtn text-white font-semibold mobile-only:w-full mobile-only:mt-3">
              {" "}
              Rejected
            </Button>
          ) : (
            " "
          )}
        </div>
        </div>
      </div>
      
        {quote?.QuoteStatus?.status==="Awaiting approval" && <div>
          <h1 className="font-semibold text-lg mb-5">Quoted Parts</h1>
          <div className="overflow-x-auto">
          <table className="min-w-full border-collapse">
            <thead className=" text-white">
              <tr>
                <th className="border-2 border-black w-[300px] md:w-[180px] py-6 bg-black">Part Type</th>
                <th className="border-2 border-black p-2 px-3 md:px-1 bg-black">Quantity</th>
                <th className="border-2 border-black p-2 px-4 md:px-3 bg-black">Price</th>
                <th className="border-2 border-black p-2 px-3 md:px-1 bg-black">Freight Price</th>
                <th className="border-2 border-black p-2 px-3 md:px-2 bg-black">Warranty</th>
                <th className="border-2 border-black p-2 px-3 bg-black">Total Price</th>
                <th className="p-2 w-[100px] md:min-w-[0px] md:px-1"></th>
                {/* <th className="p-2 min-w-[100px] md:min-w-[80px] sm:min-w-[100px] px-1"></th> */}

              </tr>
            </thead>
            <tbody>
              <tr className="bg-white relative min-h-[90px]">
                <td className="border-2 border-black p-2 py-5 text-center flex items-center">
                    <p>
                  {singleRequest?.Part ? singleRequest?.Part?.name : singleRequest?.custom_part_type}
                    </p>
                {/* <Tooltip className="w-48 h-12 p-2 text-center" content={quote?.notes} size="md" isDisabled={!quote?.notes || quote?.notes==""}>
                    <div>
                    <IoInformationCircle size={20}></IoInformationCircle>
                    </div>
                  </Tooltip> */}
                    <Tooltip
                      className="w-56 h-52 p-4 text-center overflow-y-auto whitespace-normal justify-center" // Handle text overflow properly
                      content={
                        <div className="px-1 py-2">
                          <div className="text-small font-bold mt-5">Notes</div>
                          <div className="text-tiny">{quote.notes}</div>
                        </div>
                      }
                      size="md"
                      isDisabled={!quote?.notes || quote?.notes === ""}
                    >
                      <div>
                        <IoInformationCircle size={20} />
                      </div>
                    </Tooltip>
                </td>
                <td className="border-2 border-black p-2 text-center">{singleRequest?.quantity}</td>
                <td className="border-2 border-black p-2 text-center">${quote.part_price}</td>
                <td className="border-2 border-black p-2 text-center">${quote?.freight_price}</td>
                <td className="border-2 border-black p-2 text-center">{quote?.warrantyDuration?.duration} {quote?.warrantyDuration?.unit}<br/>${quote?.warranty_price ? quote?.warranty_price : 0}</td>
                <td className="border-2 border-black p-2 text-center">${quote?.total_price}</td>
                <td className="text-center ">
                  <div className="bg-white absolute top-2 right-[-3.25em] md:right-0 md:top-2">
                    <button className="bg-lightBlue text-white px-3 py-1 rounded" onClick = {approval === "accept" ? "" : handleApprove}>
                      {approval === "accept" ? "Check Status" : "Accept Quote"}
                    </button>
                  </div>
                </td>
              </tr>
              { quote?.QuoteOptions?.length > 0 && quote?.QuoteOptions?.map((option, index) => (
                <tr key={index} className="bg-white relative">
                  <td className="border-2 border-black p-2 py-5 text-center flex items-center justify-center">
                  {/* <Tooltip content={option?.notes} isDisabled={!option?.notes || option?.notes==""}>
                    <p>
                  <IoInformationCircle></IoInformationCircle>
                      {option.Part?.name ? option.Part?.name : option?.custom_part_type}  
                    </p>
                  </Tooltip> */}
                  <p>{option.Part?.name ? option.Part?.name : option?.custom_part_type}</p>
                  <Tooltip
                    className="w-56 h-52 p-4 text-center overflow-y-auto whitespace-normal" // Handle text overflow properly
                    content={
                      <div className="px-1 py-2">
                        <div className="text-small font-bold mt-5">Notes</div>
                        <div className="text-tiny">{option.notes}</div>
                      </div>
                    }
                    size="md"
                    isDisabled={!option?.notes || option?.notes === ""}
                  >
                    <div>
                      <IoInformationCircle size={20} />
                    </div>
                  </Tooltip>
                  </td>
                  <td className="border-2 border-black p-2 text-center">{option.quantity}</td>
                  <td className="border-2 border-black p-2 text-center">${option.part_price}</td>
                  <td className="border-2 border-black p-2 text-center">${option?.freight_price}</td>
                  <td className="border-2 border-black p-2 text-center">{option?.warrantyDuration?.duration} {option?.warrantyDuration?.unit}<br/>${option?.warranty_price ? (option.warranty_price) : 0  }</td>
                  <td className="border-2 border-black p-2 text-center">${option.total}</td>
                  <td className="text-center w-[100px]">
                    <div className="bg-white absolute top-2 right-[-3.25em] md:right-0 md:top-2">
                      <button className="bg-lightBlue text-white px-3 py-1 rounded" onClick={() => getApprovalOffer(option)}>
                        Accept Quote
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>}

      <div className="mt-5">
        <ChatBox quoteId={id} />
      </div>

      {/* ----- modal for reject ----- */}
      <Modal size={"xs"} isOpen={isOpen} onClose={onClose} className="p-3">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody>
                <p className="text-base font-semibold text-center">
                  Are you sure?
                </p>
              </ModalBody>
              <ModalFooter className="grid grid-cols-2 justify-between p-4 mt-4">
                <Button
                  className="border-2 hover:borderfont-semibold bg-transparent hover:bg-orange-400 hover:text-white border-orange-400 text-orange-400"
                  onPress={onClose}
                >
                  No
                </Button>
                <Button
                  className="border-2 bg-transparent hover:bg-green-400 hover:text-white font-semibold border-green-400 text-green-400"
                  onClick={handleReject}
                >
                  Yes
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};
// };

export default UpdateStatus;
