import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  SelectItem,
  Textarea,
  useDisclosure,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Switch,
} from "@nextui-org/react";
import { inputStyle, inputWrapperStyle } from "../../data/inputStyleData";
import {
  getAllSalesRep,
  getAllPartType,
} from "../../store/slices/customerSlice";
import { handleTypeNumber, imageResizer } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import QuoteLayout from "../../layouts/QuoteLayout";
import { useNavigate } from "react-router-dom";
import { IoAddSharp } from "react-icons/io5";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import AddAssignee from "./AddAssignee";
import { toast } from "react-toastify";
import { IoMdAdd } from "react-icons/io";
import { GoDash } from "react-icons/go";
import axios from "axios";
import RequestDetails from "./RequestDetails";
import { getWarranty } from "../../store/slices/adminSlice";

const QuoteGen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState();
  const [images, setImages] = useState(new Set([]));
  const [customFields, setCustomFields] = useState([]);
  const [options, setoptions] = useState([]);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [show, setShow] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      part_type: [
        {
          part_id: 0,
          price: 0,
          quantity: 0,
          freight_price: 0,
          warranty: "",
          notes: "",
          warrantyOption: "",
          warranty_price: 0,
          applyTax: false
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "part_type",
  });
  const warrantyOption = watch("warranty_option");
  const { warranty } = useSelector((state) => state.admin);
  const { reps, part_type } = useSelector((state) => state.customer);
  const {
    isOpen: addAssignee,
    onOpen: sumAssignee,
    onClose: closeAssignee,
  } = useDisclosure();
  const customer_details = JSON.parse(localStorage.getItem("customer_request"));

  const uploadImage = async (event) => {
    setImages([]);
    for(const image of event.target.files) {
      imageResizer(image, setImages);
    }
  };


  const addCustomField = () => {
    setRemoveVisible(true);
    setCustomFields([
      ...customFields,
      {
        custom_part_type: "",
        price: 0,
        quantity: 0,
        freight_price: 0,
        warranty: "",
        warrantyOption: "",
        warranty_price: 0,
        notes: "",
        applyTax: false
      },
    ]);
  };

  // const calculateTotalPrice = (
  //   partPrice,
  //   quantity,
  //   freightPrice = 0,
  //   warrantyPrice = 0
  // ) => {
  //   // Ensure all inputs are treated as numbers and default to 0 if they are null or undefined
  //   partPrice = parseFloat(partPrice) || 0;
  //   quantity = parseInt(quantity) || 0;
  //   freightPrice = parseFloat(freightPrice) || 0;
  //   warrantyPrice = parseFloat(warrantyPrice) || 0;
  //   const totalPartPrice = partPrice * quantity;
  //   const partTax = totalPartPrice * 0.1; // GST (tax) at 10%
  //   const freightTax = freightPrice * 0.1; // Freight tax at 10%
  //   const totalTax = partTax + freightTax;
  //   const totalPrice = totalPartPrice + warrantyPrice + totalTax + freightPrice;
  //   const roundedTotalPrice = parseFloat(totalPrice.toFixed(1));
  //   return roundedTotalPrice;
  // };

  // const optionsTotal = (data) => {
  //   for (let i = 0; i < data?.length; i++) {
  //     let val = calculateTotalPrice(
  //       data[i].part_price,
  //       data[i].quantity,
  //       data[i].freight_price,
  //       data[i].warranty_price
  //     );
  //     data[i].total_price = Number(val);
  //   }
  //   return data;
  // };
//  console.log(watch('part_price')*customer_details?.quantity);
  const onSubmit = async (data) => {
    let date = `${watch("shipping_date")}T05:30:00`;
    // const total_price = Number(totalBill(customer_details?.quantity));
    let warranty = Number(data.warranty);
    data.customOptions?.forEach(option => {
      data.options.push(option);
    });
    const updatedOptions = data.options?.map((option) => {
      option.applyTax = option.applyTax || false;
      return {
        ...option,
        warranty_price: isNaN(option?.warranty_price)
        ? null
        : option?.warranty_price,
      };
    });
    data.applyTax = data.applyTax || false;
    // console.log("The updated options are: ", data.customOptions, data.options);
    // let final = optionsTotal(updatedOptions);
    delete data.customOptions;
    delete data.part_type;
    let final_data = {
      ...data,
      warranty,
      warranty_price: data.warranty_price || 0,
      shipping_date: date,
      images,
      options: updatedOptions,
      // total_price,
    };
    // console.log(final_data);
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      };
      
      let res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL_V2}/quotes/createquote/${customer_details.id}`,
        final_data,
        header
      );

      toast.success(`${res.data?.message} ⭐`);
      setTimeout(() => {
        navigate("/quotes");
      }, 2500);
    } catch (error) {
      let err = error.response.data.message;
      toast.error(err);
    }
  };


  function recieptList() {
    let freight_price = parseFloat(watch("freight_price")) || 0;
    let part_price = parseFloat(watch("part_price")) || 0;
    let warranty_price = parseFloat(watch("warranty_price")) || 0;
    
    const recieptObj = {};
    const quantity = customer_details?.quantity || 1; // Default quantity if not present
    const totalPrice = freight_price + part_price * quantity + warranty_price;
    const applyTax = watch("applyTax");
    const taxRate = 10 / 100; // 10% tax rate

    if (applyTax) {
        // Tax is applied separately
        recieptObj.totalTax = Number((totalPrice * taxRate).toFixed(2)); // Calculate tax
        recieptObj.freightPrice = freight_price; // Prices without tax
        recieptObj.partPrice = part_price;
        recieptObj.warrantyPrice = warranty_price;
        recieptObj.totalPrice = Number((totalPrice + (totalPrice*10)/100).toFixed(2)); // Total without tax
    } else {
        // Tax is already included in the price, deduct it from each
        recieptObj.totalTax = Number((totalPrice - (totalPrice * 10)/11).toFixed(2)); // Calculate total tax
        recieptObj.freightPrice = Number((freight_price / (1 + taxRate)).toFixed(2)); // Deduct tax from each price
        recieptObj.partPrice = Number((part_price / (1 + taxRate)).toFixed(2));
        recieptObj.warrantyPrice = Number((warranty_price / (1 + taxRate)).toFixed(2));
        recieptObj.totalPrice = Number(totalPrice) // Total after tax deduction
    }

    return recieptObj;
}


  const bill = recieptList();
  // const totalBill = (quantity) => {
  //   const totalTax = calculateTotalTax(quantity);
  //   const part_price = watch("part_price") || 0;
  //   const freight_price = watch("freight_price") || 0;
  //   const warranty_price = watch("warranty_price") || 0;
  //   // console.log(totalTax, part_price, quantity, part_price*quantity);
  //   let total_bill = totalTax + (part_price * quantity)
  //     // freight_price +
  //     // warranty_price;
  //     // console.log(total_bill);
  //   return Number(total_bill).toFixed(1);
  // };

  // useEffect(() => {
  //   getCustomerDetails(customer_details?.customer_id);
  // }, [customer_details?.customer_id]);

  useEffect(() => {
    dispatch(getAllSalesRep());
    dispatch(getAllPartType());
    dispatch(getWarranty());
  }, []);

  useEffect(() => {
    reset({
      shipping_address: users?.address_line_1,
    });
  }, [users]);

  return (
    <div className="md:px-7 md:py-3">
      <RequestDetails />
      <div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <QuoteLayout heading={"Price Details"}>
              <div className="mb-2 md:mb-0">
                <Input
                  type="number"
                  label="Part Price $"
                  placeholder="Price $"
                  variant="bordered"
                  labelPlacement={"outside"}
                  description={""}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleTypeNumber}
                  {...register("part_price", {
                    required: true,
                    valueAsNumber: true,
                  })}
                />
                {errors.part_price && (
                  <p className="text-xs text-red-500 pl-2 pt-2">
                    Enter part price
                  </p>
                )}
              </div>
              <div className="mb-2 md:mb-0">
                <Input
                  type="number"
                  label="Freight Price $"
                  placeholder="Freight Price $"
                  variant="bordered"
                  labelPlacement={"outside"}
                  description={""}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleTypeNumber}
                  {...register("freight_price", {
                    required: true,
                    valueAsNumber: true,
                  })}
                />
                {errors.freight_price && (
                  <p className="text-xs text-red-500 pl-2 pt-2">
                    Enter freight price
                  </p>
                )}
              </div>
               <Controller
                  name="applyTax"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      size="sm"
                      checked={field.value}
                      onChange={field.onChange}
                    >Price exclusive of tax</Switch>
                  )}
                />
            </QuoteLayout>
            <QuoteLayout heading={"Shipping and Warranty"}>
            <div className="mb-2 md:mb-0">
                <Select
                  variant="bordered"
                  labelPlacement={"outside"}
                  label="Select Sales Representative"
                  placeholder="Select Sales Rep"
                  className="w-full text-black"
                  {...register("sales_repId", {
                    required: false,
                    valueAsNumber: true,
                  })}
                >
                  <SelectItem key="" onClick={() => sumAssignee()}>
                    <div className="flex gap-2 items-center">
                      <IoAddSharp style={{ fontSize: "0.9rem" }} />
                      <p>Add Sales Reps</p>
                    </div>
                  </SelectItem>
                  {reps.map((rep) => (
                    <SelectItem key={rep.id} value={rep.SalesRepName}>
                      {rep.SalesRepName}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              <div className="mb-2 md:mb-0">
                <Input
                  type="date"
                  label="Date you can send the part"
                  variant="bordered"
                  labelPlacement={"outside"}
                  placeholder="Enter date"
                  description={""}
                  className="w-full text-black"
                  {...register("shipping_date", { required: true })}
                />
                {errors.shipping_date && (
                  <p className="text-xs text-red-500 pl-2 pt-2">
                    Enter Shipping Date
                  </p>
                )}
              </div>
              {/* <div className="mb-2 md:mb-0">
                <Input
                  type="text"
                  label="Shipping address"
                  variant="bordered"
                  labelPlacement={"outside"}
                  placeholder="Enter date"
                  description={""}
                  className="w-full text-black"
                  {...register("shipping_address", { required: true })}
                />
                {errors.shipping_address && (
                  <p className="text-xs text-red-500 pl-2 pt-2">
                    Enter Shipping Date
                  </p>
                )}
              </div> */}
              <div className="mb-2 md:mb-0">
                <Input
                  type="file"
                  label="Upload images ( Max 5 image )"
                  classNames={{
                    input: inputStyle,
                    inputWrapper: inputWrapperStyle,
                  }}
                  multiple
                  accept="image/png, image/jpeg"
                  labelPlacement={"outside"}
                  placeholder="Choose images"
                  description={""}
                  {...register("images", { required: false })}
                  onChange={(e) => uploadImage(e)}
                />
                {errors.images && (
                  <p className="text-xs text-red-500 pl-2 leading-3 pt-2">
                    Upload image
                  </p>
                )}
              </div>
              <div className="mb-2 md:mb-0">
                <Select
                  labelPlacement={"outside"}
                  variant="bordered"
                  className="w-full"
                  label="Warranty Duration"
                  id="duration"
                  placeholder="warranty duration"
                  {...register("warranty", {
                    required: true,
                    valueAsNumber: true,
                  })}
                >
                  {warranty?.map((item, index) => {
                    const val = item.duration + " " + item.unit;

                    return (
                      item.duration !== 0 && <SelectItem key={item.id} value={item.id}>
                        {val}
                      </SelectItem>

                    );
                  })}
                </Select>
                {errors.warranty && (
                  <p className="text-xs text-red-500 pl-2 pt-2">
                    Enter warranty duration
                  </p>
                )}
              </div>
              <div className="mb-2 md:mb-0">
                <Input
                  type="text"
                  label="Interchange Number (Optional)"
                  placeholder="Enter interchange number"
                  variant="bordered"
                  labelPlacement={"outside"}
                  description={""}
                  {...register("interchange_number", {
                    required: false,
                  })}
                />
              </div>
            </QuoteLayout>
            <QuoteLayout heading={"Add Your Own Warranty Option"}>
              <div className="mb-2 md:mb-0">
                <Input
                  type="text"
                  label="Warranty Option"
                  placeholder="warranty option"
                  variant="bordered"
                  labelPlacement={"outside"}
                  description={""}
                  {...register("warranty_option", { required: false })}
                />
              </div>
              <div className="mb-2 md:mb-0">
                <Input
                  type="number"
                  label="Warranty Price $"
                  placeholder="Warranty Price $"
                  variant="bordered"
                  labelPlacement={"outside"}
                  description={""}
                  onKeyDown={handleTypeNumber}
                  onWheel={(e) => e.target.blur()}
                  {...register("warranty_price", {
                    required: warrantyOption ? true : false,
                    valueAsNumber: true,
                  })}
                />
                {errors.warranty_price && (
                  <p className="text-xs text-red-500 pl-2 pt-2">
                    Enter warranty price
                  </p>
                )}
              </div>
            </QuoteLayout>
            
            <div className="flex mt-2 pb-5 pt-5 ">
              <IoMdAdd className="m-1 ml-0" onClick={() => setShow(true)} />
              <h1 className="text-base sm:text-xl font-semibold" onClick={() => setShow(true)}>
                {" "}
                Other Options
              </h1>
            </div>
            {show && (
              <div className="col-span-2 border-b-1 border-gray-100 pb-7">
                {fields?.map((field, index) => (
                  <div className="mt-4">
                    <div className="md:grid md:grid-cols-2 gap-4">
                      <Select
                        className="mb-2 "
                        variant="bordered"
                        label="Part Type"
                        labelPlacement={"outside"}
                        placeholder="Select Part Type"
                        name={`part_type`}
                        {...register(`options[${index}].part_type`, {
                          required: false,
                        })}
                      >
                        {part_type.map((e) => (
                          <SelectItem key={e.id} value={e.id}>
                            {e.name}
                          </SelectItem>
                        ))}
                      </Select>
                      <div className="md:grid md:grid-cols-2 gap-2">
                        <Input
                          type="number"
                          label="Part Price"
                          variant="bordered"
                          className="mb-2 "
                          labelPlacement={"outside"}
                          name={`part_price`}
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={handleTypeNumber}
                          {...register(`options[${index}].part_price`, {
                            required: false,
                            valueAsNumber: true,
                          })}
                          placeholder="Part Price"
                        />
                        <Input
                          type="number"
                          label="Quantity"
                          variant="bordered"
                          className="mb-2 "
                          labelPlacement={"outside"}
                          name={`quantity`}
                          onWheel={(e) => e.target.blur()}
                          {...register(`options[${index}].quantity`, {
                            required: false,
                            valueAsNumber: true,
                          })}
                          placeholder="Quantity"
                        />
                      </div>
                    </div>
                    <div className="md:grid md:grid-cols-2 gap-4 mb-2">
                      <Input
                        type="number"
                        label="Freight Price"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        name={`freight_price`}
                        onWheel={(e) => e.target.blur()}
                        {...register(`options[${index}].freight_price`, {
                          required: false,
                          valueAsNumber: true,
                        })}
                        placeholder="Freight Price"
                      />
                      <Select
                        labelPlacement={"outside"}
                        variant="bordered"
                        className="w-full"
                        label="Warranty Duration"
                        id="duration"
                        placeholder="warranty duration"
                        {...register(`options[${index}].warranty`, {
                          valueAsNumber: true,
                        })}
                      >
                        {warranty?.map((item, index) => {
                          const val = item.duration + " " + item.unit;
                          return (
                            <SelectItem key={item.id} value={item.id}>
                              {val}
                            </SelectItem>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="md:grid md:grid-cols-2 gap-4 mb-2">
                      <Input
                        type="text"
                        label="Warranty Option"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        name={`warranty_option`}
                        {...register(`options[${index}].warranty_option`, {
                          required: false,
                        })}
                        placeholder="Warranty Option"
                      />
                      <Input
                        type="number"
                        label="Warranty Price"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        name={`warranty_price`}
                        onWheel={(e) => e.target.blur()}
                        {...register(`options[${index}].warranty_price`, {
                          required: false,
                          valueAsNumber: true,
                        })}
                        placeholder="Warranty Price $"
                      />
                    </div>
                    <div className="md:grid md:grid-cols-2 gap-4 mb-2">
                        <Textarea
                          key={"bordered"}
                          variant={"bordered"}
                          label="Notes"
                          labelPlacement="outside"
                          placeholder="Include details such as mileage, colour, and condition of the vehicle parts....."
                          className="col-span-2 "
                          {...register(`options[${index}].notes`, { required: false })}
                        />
                    </div>
                    <Controller
                      name={`options[${index}].applyTax`}
                      control={control}
                      defaultValue={false}
                      render={({ field }) => {
                        return (
                          <Switch
                            size="sm"
                            checked={field.applyTax}
                            onChange={field.onChange}
                            >Price exclusive of tax</Switch>
                        )
                      }}
                    />
                  </div>
                ))}

                {customFields.map((options, index) => (
                  <div className="mt-4">
                    <div className="md:grid md:grid-cols-2 gap-4">
                      <Input
                        type="text"
                        label="Custom Part Type"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        name={`custom_part_type`}
                        {...register(
                          `customOptions[${index}].custom_part_type`,
                          {
                            required: false,
                          }
                        )}
                        placeholder="Custom Part Type"
                      />
                      <div className="md:grid md:grid-cols-2 gap-2">
                        <Input
                          type="number"
                          label="Custom Price"
                          variant="bordered"
                          className="mb-2 "
                          labelPlacement={"outside"}
                          name={`part_price`}
                          onWheel={(e) => e.target.blur()}
                          {...register(
                            `customOptions[${index}].part_price`,
                            {
                              required: false,
                              valueAsNumber: true,
                            }
                          )}
                          placeholder="Custom Part Price"
                        />
                        <Input
                          type="number"
                          label="Quantity"
                          variant="bordered"
                          className="mb-2 "
                          labelPlacement={"outside"}
                          onWheel={(e) => e.target.blur()}
                          name={`quantity`}
                          {...register(
                            `customOptions[${index}].quantity`,
                            {
                              required: false,
                              valueAsNumber: true,
                            }
                          )}
                          placeholder="Quantity"
                        />
                      </div>
                    </div>
                    <div className="md:grid md:grid-cols-2 gap-4">
                      <Input
                        type="number"
                        label="Freight Price"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        name={`freight_price`}
                        onWheel={(e) => e.target.blur()}
                        {...register(
                          `customOptions[${index}].freight_price`,
                          {
                            required: false,
                            valueAsNumber: true,
                          }
                        )}
                        placeholder="Freight Price"
                      />
                      <Select
                        labelPlacement={"outside"}
                        variant="bordered"
                        className="w-full"
                        label="Warranty Duration"
                        id="duration"
                        placeholder="Warranty duration"
                        {...register(
                          `customOptions[${index}].warranty`,
                          {
                            valueAsNumber: true,
                          }
                        )}
                      >
                        {warranty?.map((item, index) => {
                          const val = item.duration + " " + item.unit;
                          return (
                            <SelectItem key={item.id} value={item.id}>
                              {val}
                            </SelectItem>
                          );
                        })}
                      </Select>
                    </div>

                    <div className="md:grid md:grid-cols-2 gap-4">
                      <Input
                        type="text"
                        label="Warranty Option"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        name={`warranty_option`}
                        {...register(
                          `customOptions[${index}].warranty_option`,
                          {
                            required: false,
                          }
                        )}
                        placeholder="Warranty Option"
                      />
                      <Input
                        type="number"
                        label="Warranty Price"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        name={`warranty_price`}
                        onWheel={(e) => e.target.blur()}
                        {...register(
                          `customOptions[${index}].warranty_price`,
                          {
                            required: false,
                            valueAsNumber: true,
                          }
                        )}
                        placeholder="Warranty Price $"
                      />
                    </div>

                    <div className="md:grid md:grid-cols-2 gap-4 mb-2">
                        <Textarea
                          key={"bordered"}
                          variant={"bordered"}
                          label="Notes"
                          labelPlacement="outside"
                          placeholder="Include details such as mileage, colour, and condition of the vehicle parts....."
                          className="col-span-2 "
                          {...register(`customOptions[${index}].notes`, { required: false })}
                        />
                    </div>
                    <Controller
                      name={`customOptions[${index}].applyTax`}
                      control={control}
                      render={({ field }) => (
                        <Switch
                          size="sm"
                          checked={field.applyTax}
                          onChange={field.onChange}
                        >Price exclusive of tax</Switch>
                      )}
                    />
                  </div>
                ))}

                <div className="flex justify-between">
                  <div>
                    <button
                      className="flex items-center gap-2 text-small"
                      type="button"
                      onClick={addCustomField}
                    >
                      <IoMdAdd />{" "}
                      <p className="text-md sm:text-xs">Add Custom Part</p>
                    </button>
                    <button
                      type="button"
                      className="flex items-center gap-2 text-small"
                      onClick={() =>
                        append({
                          part_id: 0,
                          price: 0,
                          quantity: 0,
                          freight_price: 0,
                          warranty: "",
                          warrantyOption: "",
                          warranty_price: 0,
                          applyTax: false
                        })
                      }
                    >
                      <IoMdAdd /> <p className="sm:text-xs">Add Part</p>
                    </button>
                  </div>
                </div>
              </div>
            )}

            <QuoteLayout heading={"Add Note"}>
              <div className="col-span-2 mb-2 md:mb-0">
                <Textarea
                  key={"bordered"}
                  variant={"bordered"}
                  label="Notes"
                  labelPlacement="outside"
                  placeholder="Include details such as mileage, colour, and condition of the vehicle parts....."
                  className="col-span-2 "
                  {...register("notes", { required: false })}
                />
              </div>
            </QuoteLayout>
            <div className="pt-5 pb-7">
              <div className="sm:grid grid-cols-2">
                <div>
                  <p className="text-base sm:text-xl font-semibold">
                    Transaction:
                  </p>
                </div>
                <div>
                  <div className="mx-2 sm:mx-6 border-b-1 border-gray-300 pb-1 grid grid-cols-2">
                    <div className="text-left leading-7 font-semibold text-xs sm:text-lg">
                      <p>
                        Parts Cost{" "}
                        <span className="text-xs">
                          ({customer_details?.quantity} Qnty)
                        </span>
                      </p>

                      <p>
                        Shipping Cost <span className="text-xs">(ex GST)</span>
                      </p>
                      <p>Warranty <span className="text-xs">(ex GST) </span></p>
                      <p>
                        Tax Total
                      </p>
                    </div>
                    <div className="text-right leading-7 text-xs sm:text-lg">
                      <p>
                        {bill.partPrice>0
                          ? "$" +
                          (
                            bill.partPrice * customer_details?.quantity
                          ).toFixed(2)
                          : "$00"}
                      </p>
                      <p>
                        {bill.freightPrice>0
                          ? "$" +
                            bill.freightPrice
                          : "$00"}
                      </p>
                      <p>
                        {bill.warrantyPrice > 0
                          ? "$" + bill.warrantyPrice
                          : "$00"}
                      </p>
                      <p>
                        $
                        {bill.totalTax > 0
                          ? bill.totalTax
                          : "00"}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between mx-2 sm:mx-6 py-2 text-sm sm:text-lg font-semibold">
                    <div>
                      <p>Grand Total</p>
                    </div>
                    <div>
                      <p>
                        {bill.totalPrice > 0
                          ? "$" + bill.totalPrice
                          : "$00"}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <button className="mobile-only:w-full sm:mx-6 bg-lightBlue text-white rounded-lg py-2 px-4 mt-2">
                      Create Quote
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <AddAssignee
            addAssignee={addAssignee}
            closeAssignee={closeAssignee}
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteGen;
