import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { STATUS } from "../constants";

const initialState = {
  body_style: [],
  transmission:[],
  fuel_type:[],
  warranty:[],
  disputeTitle:[],
  suppliertype:[],
  cancelReason:[],
  commissionRate:[],
  status: STATUS.LOADING,
  error: ""
};

export const adminSlice = createSlice({
  name: "admin",
  initialState: initialState,
  reducers: {
    bodyStyle: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        body_style : payload.bodyStyles
      }
    },
    transmission: (state, action) => {
      const { payload } = action;
      // console.log(payload)
      return {
        ...state,
        transmission : payload.transmission
      }
    },
    fuel_type: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        fuel_type: payload.fuelType
      }
    },
    disputeTitle:(state,action)=>{
      const {payload}=action;
      // console.log(payload)
      return{
        ...state,
        disputeTitle:payload.titles
      }
    },
    warranty:(state,action)=>{
      const {payload}=action;
      // console.log(payload)
      return {
        ...state,
        warranty: payload.warrantyDurations

      }
    },
    suppliertype:(state,action)=>{
      const {payload}=action;
      // console.log(payload)

      return {
        ...state,
        suppliertype: payload.supplierTypes
        

      }
    },
    cancelReason:(state,action)=>{
      const {payload}=action;
      // console.log(payload)
      return{
        ...state,
        cancelReason: payload.cancelReasons
        
      }
    },
    commissionRate:(state,action)=>{
      const {payload}=action;
      // console.log(payload)
      return{
        ...state,
        commissionRate:payload.commissionRate.rate,  
      }
    },

    changeStatus: (state, action) => {
        return {
            ...state,
            status: action.payload,
          };
    },
    isError: (state, action) => {
        return {
            ...state,
            error: action.payload,
          };
    }
  }
});

export const {
  bodyStyle,
  transmission,
  fuel_type,
  warranty,
  disputeTitle,
  changeStatus,
  suppliertype,
  commissionRate,
  cancelReason,
  isError
} = adminSlice.actions;

export default adminSlice.reducer;

export const getBodyStyle = () => {
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/bodystyles`,
        header
      );
      let data = res.data.details;
      dispatch(bodyStyle(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response?.data?.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
};

export const getTransmission = () => {
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/transmission`,
        header
      );
      let data = res.data.details;
      // console.log(data)
      dispatch(transmission(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response?.data?.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
};

export const getFuelType = () => {
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/fueltype`,
        header
      );
      let data = res.data.details;
      dispatch(fuel_type(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response?.data?.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
};


export const getWarranty=()=>{
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/warranty`,
        header
      );
      let data = res.data.details;
      dispatch(warranty(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response.data.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
}
export const getDisputeTitle=()=>{
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/disputetitle`,
        header
      );
      let data = res.data.details;
      // console.log(res.data)
      dispatch(disputeTitle(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response?.data.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
}

export const getSuppliertype=()=>{
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/suppliertype`,
        header
      );
      let data = res.data.details;
      dispatch(suppliertype(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response?.data?.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
}
export const getCancelReason=()=>{
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/cancelreason`,
        header
      );
      let data = res.data.details;
      dispatch(cancelReason(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response?.data.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
}

export const getCommissionRate=()=>{
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      dispatch(changeStatus(STATUS.LOADING));
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/commssionrate`,
        header
      );
      let data = res.data.details;
      dispatch(commissionRate(data));
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response?.data?.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
}


