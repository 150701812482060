import React from 'react';
import ContactImg from "../assets/images/customer_support.jpg";
import { Button, Input, Textarea } from "@nextui-org/react";
import {Accordion, AccordionItem} from "@nextui-org/react";
import { faq } from '../data/usersData';
import { useForm } from "react-hook-form";

const HelpFeedback = () => {
  const [selectedKeys, setSelectedKeys] = React.useState(new Set(["1"]));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data)
  }

  return (
    <div className='px-0 sm:px-8 lg:px-16'>
      {/*---- contact us section ----*/}
      <div>
        <div className='border-b-1 border-gray-400 mb-5 flex flex-col sm:flex-row gap-2 items-center pb-1'>
          <h2 className='text-lg -mb-2 sm:mb-0 sm:text-xl font-semibold'>Contact Us </h2>
          <p className=' text-xs sm:text-sm text-gray-400'>- Your One-Stop Shop for Vehicle Parts</p>
        </div>
        <div className='grid md:grid-cols-2 gap-3 items-center'>
          <img src={ContactImg} alt="contact-us" width="80%" height="305px" />
          <div>
            <p className='text-sm mb-2'>
              We value your feedback, inquiries, and concerns. Our dedicated team is here to assist you with any questions you may have regarding our website or the vehicle parts we offer. We strive to provide exceptional customer service and ensure that your experience with us is nothing short of excellent.
              We value your time and strive to provide timely responses to all inquiries. Our goal is to ensure that you have a smooth and hassle-free experience when contacting us
            </p>
          </div>
        </div>
        <div className='grid md:grid-cols-2'>
          <div className='pt-9 pr-0 sm:pr-11'>
            <p className='text-base font-semibold border-b-1 border-gray-200 pb-2 mr-8 mb-2'>Contact Form</p> 
            <p className='text-sm mb-3'>
            Whether you have a question about a specific part, need assistance with your order, or require technical support, our knowledgeable team is ready to help. We understand that every customer's needs are unique, and we are committed to providing personalized assistance to ensure your satisfaction.
            </p>
            <p className='text-sm '>you can fill out the contact form on our website. Simply provide your name, email address, and a brief message outlining your query or concern. Our team will get back to you as soon as possible.</p>
          </div>
          <div className='py-2 px-0 sm:p-2'>
            <div className='bg-blue-950 w-full p-5 text-white box-border'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid sm:grid-cols-2 gap-2 mb-4'>
                  <div>
                    <Input
                      type="text"
                      label="First Name"
                      variant="bordered"
                      classNames={{
                        label: "text-white",
                        input: ["focus: border-white","placeholder:text-white"]
                      }}
                      labelPlacement={"outside"}
                      placeholder="First Name"
                      description={""}
                      {...register("first_name", { required: true })}
                    />
                    {errors.first_name && ( <p className="text-xs text-red-500 pl-2 pt-2">
                      Enter first name </p> )}
                  </div>
                  <div>
                    <Input
                      type="text"
                      label="Last Name"
                      variant="bordered"
                      labelPlacement={"outside"}
                      placeholder="Last Name"
                      classNames={{
                        label: "text-white",
                        input: ["focus: border-white","placeholder:text-white"]
                      }}
                      description={""}
                      {...register("last_name", { required: true })}
                    />
                    {errors.last_name && ( <p className="text-xs text-red-500 pl-2 pt-2">
                      Enter warranty duration </p> )}
                  </div>
                </div>
                <div className='mb-4'>
                    <Input
                      type="email"
                      label="Email"
                      variant="bordered"
                      labelPlacement={"outside"}
                      placeholder="Email"
                      classNames={{
                        label: "text-white",
                        input: ["focus: border-white","placeholder:text-white"]
                      }}
                      description={""}
                      {...register("email", { required: true })}
                    />
                    {errors.email && ( <p className="text-xs text-red-500 pl-2 pt-2">
                      Enter last name </p> )}
                </div>
                <div className='mb-5'>
                  <Textarea
                    key={"bordered"}
                    variant={"bordered"}
                    label="Message"
                    labelPlacement="outside"
                    placeholder="Enter your message"
                    classNames={{
                      label: "text-white",
                      input: ["focus: border-white","placeholder:text-white"]
                    }}
                    {...register("message", {required: true})}
                  />
                  {errors.message && ( <p className="text-xs text-red-500 pl-2 pt-2">
                      Provide your message </p> )}
                </div>
                <Button className='w-full bg-lightBlue text-white text-base font-semibold'>SEND</Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ---- faq section ---- */}
      <div className='mb-10'>
        <h2 className='text-base sm:text-lg font-semibold border-b-1 border-gray-200 pb-2 mt-5 mb-3'>FAQ's</h2>
        <Accordion
          selectedKeys={selectedKeys}
          onSelectionChange={setSelectedKeys}
        >
          {faq.map((item)=>
            <AccordionItem key={item.id} aria-label={item.qns} title={item.qns} 
            classNames={{ title: "text-sm sm:text-base font-semibold", content:"text-xs sm:text-sm" }}
            >
              {item.ans}
            </AccordionItem>
          )}
        </Accordion>
      </div>
    </div>
  )
}

export default HelpFeedback;